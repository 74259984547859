import { useEffect, useMemo } from 'react'
import type { APIDocument, APIDocumentRaw } from '@visorpro/client'
import { RawTypeConverter } from '@visorpro/client'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { apiClient, visorPRORestClient } from '../util/api'
import { ObservableTask } from '../util/observable-task'
import type { Stores } from '../util/store'
import { type APIUpdateDocumentRequest } from './documents'

export interface APIGetDataSetDocumentsResponse {
    total: number
    items: APIDocumentRaw[]
}

export type StoredDocument = Omit<APIDocument, 'models'> & {
    model_ids: string[]
}

export class DataSetDocumentsStore {
    public dataSetId: string = ''
    public documentsByIdByDatasets: Record<
        string,
        Record<string, APIDocument>
    > = {}

    constructor(private readonly stores: Stores) {
        makeObservable(this, {
            dataSetId: observable,
            documentsByIdByDatasets: observable,
        })
    }

    public get documentsById() {
        return this.documentsByIdByDatasets[this.dataSetId] || {}
    }

    public set documentsById(documents: Record<string, APIDocument>) {
        this.documentsByIdByDatasets[this.dataSetId] = documents
    }

    public get documents() {
        return computed(() => Object.values(this.documentsById)).get()
    }

    public get isFetching() {
        return this.fetchDataSetDocuments.isRunning
    }

    public get isFetched() {
        return this.documentsByIdByDatasets[this.dataSetId] !== undefined
    }

    public get documentsCount() {
        return this.documents.length
    }

    public get isUpdating() {
        return (
            this.setDocumentEnabled.isRunning ||
            this.updateDocument.isRunning ||
            this.addDocumentToModels.isRunning ||
            this.removeDocumentFromModels.isRunning
        )
    }

    public fetchDataSetDocuments = new ObservableTask(
        async (dataSetId: string) => {
            runInAction(() => {
                this.dataSetId = dataSetId
            })

            const response =
                await apiClient.get<APIGetDataSetDocumentsResponse>(
                    `/data-set/${dataSetId}/document`,
                )

            runInAction(() => {
                const docs = response.data.items.map((doc) =>
                    RawTypeConverter.fixDocument(doc),
                )
                this.documentsById = Object.fromEntries(
                    docs.map((doc) => [doc.id, doc]),
                )
            })
        },
    )

    public setDocumentEnabled = new ObservableTask(
        async (documentId: string, dataSetId: string, isEnabled: boolean) => {
            const doc = await visorPRORestClient.document.updateDocument(
                documentId,
                { is_enabled: isEnabled },
            )
            runInAction(() => {
                this.documentsById[documentId] = doc
            })
        },
    )

    public updateDocument = new ObservableTask(
        async (
            documentId: string,
            dataSetId: string,
            options: APIUpdateDocumentRequest,
        ) => {
            const doc = await visorPRORestClient.document.updateDocument(
                documentId,
                options,
            )
            runInAction(() => {
                this.documentsById[documentId] = doc
            })
        },
    )

    public addDocumentToModels = new ObservableTask(
        async (documentId: string, modelIds: string[]) => {
            await Promise.all(
                modelIds.map((modelId) =>
                    visorPRORestClient.model.addDocumentsToProductModel(
                        modelId,
                        [documentId],
                    ),
                ),
            )

            runInAction(() => {
                const document = this.documentsById[documentId]
                const d2m = document.document_to_product_model || []
                const newD2M = d2m.concat(
                    modelIds.map((modelId) => ({
                        product_model: this.stores.models.modelsById[modelId],
                    })),
                )
                document.document_to_product_model = newD2M
            })
        },
    )

    public removeDocumentFromModels = new ObservableTask(
        async (documentId: string, modelIds: string[]) => {
            await Promise.all(
                modelIds.map((modelId) =>
                    visorPRORestClient.model.removeDocumentsFromProductModel(
                        modelId,
                        [documentId],
                    ),
                ),
            )

            runInAction(() => {
                const document = this.documentsById[documentId]
                const d2m = document.document_to_product_model || []
                const newD2M = d2m.filter(
                    (r) => !modelIds.includes(r.product_model.id),
                )
                document.document_to_product_model = newD2M
            })
        },
    )
}

export const useDataSetDocuments = (stores: Stores, dataSetId: string) => {
    useEffect(() => {
        void stores.dataSetDocuments.fetchDataSetDocuments.run(dataSetId)
    }, [dataSetId, stores])

    const documents = useMemo(
        () => stores.dataSetDocuments.documents,
        [stores.dataSetDocuments.documents],
    )

    return documents
}

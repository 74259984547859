import type { APIDocument, APIDocumentRaw } from '@visorpro/client'
import { RawTypeConverter } from '@visorpro/client'
import { DateTime } from 'luxon'

interface APIParserBase {
    id: string
    name: string
}

export interface APIParserRaw extends APIParserBase {
    documents: APIDocumentRaw[]
    rule_sets: APIRuleSetRaw[]
    created_at: string
    updated_at: string
}

export interface APIParser extends APIParserBase {
    documents: APIDocument[]
    rule_sets: APIRuleSet[]
    created_at: DateTime
    updated_at: DateTime
}

export interface APIRuleSetTextRuleBase {
    id: string
    kind: 'text'
    rule_set_id: string
    hierarchy: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'ignored'
    font: string | null
    font_negated: boolean
    size_exact: number | null
    size_exact_negated: boolean
    size_between: [number, number] | null
    size_between_negated: boolean
    color: string | null
    color_negated: boolean
    casing: 'uppercase' | 'lowercase' | null
    casing_negated: boolean
    within_rect: [number, number, number, number] | null
    within_rect_negated: boolean
    within_images: boolean | null
}

export interface APIRuleSetTextRuleRaw extends APIRuleSetTextRuleBase {
    created_at: string
    updated_at: string
}

export interface APIRuleSetTextRule extends APIRuleSetTextRuleBase {
    created_at: DateTime
    updated_at: DateTime
}

interface APIRuleSetBase {
    id: string
    parser_id: string
    from_page: number | null
    to_page: number | null
}

export interface APIRuleSetRaw extends APIRuleSetBase {
    rules: APIRuleSetTextRuleRaw[]
    created_at: string
    updated_at: string
}

export interface APIRuleSet extends APIRuleSetBase {
    rules: APIRuleSetTextRule[]
    created_at: DateTime
    updated_at: DateTime
}

export const rawParserToParser = (rawParser: APIParserRaw): APIParser => ({
    ...rawParser,
    documents: rawParser.documents.map((document) =>
        RawTypeConverter.fixDocument(document),
    ),
    rule_sets: rawParser.rule_sets.map(rawRuleSetToRuleSet),
    created_at: DateTime.fromISO(rawParser.created_at),
    updated_at: DateTime.fromISO(rawParser.updated_at),
})

export const rawRuleSetToRuleSet = (rawRuleSet: APIRuleSetRaw): APIRuleSet => ({
    ...rawRuleSet,
    rules: rawRuleSet.rules.map((rawRule) => ({
        ...rawRule,
        created_at: DateTime.fromISO(rawRule.created_at),
        updated_at: DateTime.fromISO(rawRule.updated_at),
    })),
    created_at: DateTime.fromISO(rawRuleSet.created_at),
    updated_at: DateTime.fromISO(rawRuleSet.updated_at),
})

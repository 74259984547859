import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BreadCrumbs } from '../../components/breadcrumbs'
import type { Stores } from '../../util/store'
import './index.css'

export interface ParserDetailsPageProps {
    stores: Stores
}

export const ParserDetailsPage: FC<ParserDetailsPageProps> = observer(
    ({ stores }) => {
        const { parserId } = useParams()
        const parser = stores.parser.parsersById[parserId!]

        useEffect(() => {
            if (!parser) {
                stores.parser.fetchParsers()
            }
        }, [parser, stores.parser])

        if (!parser) return null


        return (
            <div className="parser-details">
                <BreadCrumbs
                    name={parser.name}
                    path={[
                        {
                            name: 'Parsers',
                            to: '/parsers',
                        },
                    ]}
                />
            </div>
        )
    },
)

import { observer } from 'mobx-react-lite'
import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'
import { Modal } from '../../components/modal'
import { SelectableList } from '../../components/modals'
import { useCategories } from '../../stores/categories-store'
import type { Stores } from '../../util/store'
import type { Placement } from '@floating-ui/react'

export interface CategoryModalProps {
    anchor?: HTMLElement | null
    placement?: Placement
    stores: Stores
    onClose(): void
    onCategoryIdChange(id: string): void
    style?: CSSProperties
    containerStyle?: CSSProperties
    onBack?(): void
    selectedIds?: Set<string>
}

export const CategoryModal: FC<CategoryModalProps> = observer(
    ({
        anchor,
        placement,
        stores,
        onClose,
        onCategoryIdChange,
        style,
        containerStyle,
        onBack,
        selectedIds,
    }) => {
        const categories = useCategories(stores)

        const createCategory = useCallback(
            async (name: string) => {
                const categoryId = await stores.categories.create.run({ name })

                if (categoryId) {
                    onCategoryIdChange(categoryId)
                }
            },
            [onCategoryIdChange, stores.categories.create],
        )

        return (
            <Modal
                placement={placement}
                anchor={anchor}
                onClose={onClose}
                style={style}
                containerStyle={containerStyle}
                onBack={onBack}>
                <SelectableList
                    items={categories}
                    selectedIds={selectedIds}
                    onSelect={(item) => onCategoryIdChange(item.id)}
                    onSubmit={createCategory}
                    isLoading={stores.categories.getCategories.isRunning}
                    placeholder="Search category"
                />
            </Modal>
        )
    },
)

import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Modal, useModal } from '../../components/modal'
import { PdfPage } from '../../components/pdf-page'
import { TextButton } from '../../components/text-button'
import { usePdfDocument } from '../../util/pdf'
import type { Stores } from '../../util/store'
import { IssueSelectionModal } from './issue-selection-modal'

export interface DocumentSectionPreviewProps {
    request: APIGetRandomSectionsRequest
    stores: Stores
    onClose(): void
}

const getSectionPageNumber = (urlString: string) => {
    const url = new URL(urlString)

    // Extract the hash and split it by '='
    const hash = url.hash.substring(1) // Removes the '#' at the beginning
    const parts = hash.split('=')

    if (parts[0] === 'page') {
        const result = parseInt(parts[1])

        if (!isNaN(result)) {
            return result
        }
    }
}

export const DocumentSectionPreview: FC<DocumentSectionPreviewProps> = observer(
    ({ request, stores, onClose }) => {
        useEffect(() => {
            stores.sectionPreview.getSectionsIfNeeded.run(request)
        }, [stores.sectionPreview, request])

        const section = stores.sectionPreview.sectionUnderReview

        const doc = usePdfDocument(section?.section_url)
        const pageInSection = section?.section_url
            ? getSectionPageNumber(section.section_url)
            : 1

        const visitNextSection = useCallback(() => {
            stores.sectionPreview.visitNextSection()
        }, [stores.sectionPreview])

        const pages = useMemo(() => {
            if (!doc.pdfDoc || !section) {
                return []
            }

            const result: JSX.Element[] = []

            for (let i = 0; i < doc.numPages; i++) {
                const pageNumber = i + 1
                const isSelected = pageNumber === pageInSection
                result.push(
                    <div
                        key={i}
                        style={{ display: 'flex', flexDirection: 'column' }}>
                        <PdfPage
                            doc={doc}
                            pageNumber={i + 1}
                            style={{
                                marginTop: i === 0 ? 0 : '0.5rem',
                            }}
                            onLoad={(element) => {
                                if (isSelected) {
                                    pagesContainerRef.current?.scrollTo({
                                        top: element.offsetTop,
                                    })
                                }
                            }}
                        />

                        <div
                            style={{
                                alignSelf: 'center',
                                color: isSelected
                                    ? 'var(--purple-color)'
                                    : 'var(--foreground-color)',
                                marginTop: '0.25rem',
                            }}>
                            {i + 1} of {doc.numPages}
                        </div>
                    </div>,
                )
            }

            return result
        }, [doc, section, pageInSection])

        const content = useMemo(() => {
            return section?.content.split('\n').map((line, i) => (
                <div
                    key={i}
                    style={{
                        marginTop: i === 0 ? 0 : '1rem',
                    }}>
                    {line}
                </div>
            ))
        }, [section])

        const issuesModal = useModal()

        const containerRef = useRef<HTMLDivElement>(null)
        const pagesContainerRef = useRef<HTMLDivElement>(null)

        const stopPropagation = useCallback((e: React.MouseEvent) => {
            e.stopPropagation()
        }, [])

        const issueIdsInSection = new Set(
            section?.issue_instances.map((instance) => instance.issue_id),
        )

        return (
            <Modal
                onClose={onClose}
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                }}>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateRows: 'auto 1fr auto',
                        gridRowGap: '1rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        height: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <div
                        style={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <div
                            onClick={stopPropagation}
                            style={{
                                backgroundColor: 'var(--background-dark-color)',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                            }}>
                            <h2>
                                {section?.document_title} -
                                <span style={{ color: 'var(--purple-color)' }}>
                                    {' Page: '}
                                    {pageInSection}
                                </span>
                            </h2>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxSizing: 'border-box',
                            overflow: 'auto',
                        }}>
                        <div
                            ref={pagesContainerRef}
                            onClick={stopPropagation}
                            style={{
                                height: '100%',
                                width: '50%',
                                backgroundColor: 'var(--background-color)',
                                maxWidth: '600px',
                                overflow: 'auto',
                                boxSizing: 'border-box',
                            }}>
                            {pages}
                        </div>

                        <div
                            onClick={stopPropagation}
                            style={{
                                height: '100%',
                                width: '50%',
                                backgroundColor: 'var(--background-dark-color)',
                                maxWidth: '600px',
                                color: 'var(--foreground-color)',
                                padding: '1rem',
                                overflow: 'auto',
                                boxSizing: 'border-box',
                            }}>
                            {content}
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignContent: 'center',
                                justifyContent: 'center',
                                boxSizing: 'border-box',
                            }}>
                            <div
                                onClick={stopPropagation}
                                style={{
                                    backgroundColor:
                                        'var(--background-dark-color)',
                                    padding: '1rem',
                                    borderRadius: '0.5rem',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxSizing: 'border-box',
                                    gap: '1rem',
                                }}>
                                <TextButton
                                    style={{ color: 'var(--red-color)' }}
                                    onClick={() =>
                                        issuesModal.open({
                                            target: containerRef.current!,
                                        })
                                    }>
                                    Select issue
                                </TextButton>

                                <TextButton
                                    style={{ color: 'var(--purple-color)' }}
                                    onClick={visitNextSection}>
                                    Next
                                </TextButton>
                            </div>
                        </div>
                    </div>
                </div>

                {issuesModal.state && (
                    <IssueSelectionModal
                        anchor={issuesModal.state.anchor}
                        onClose={issuesModal.close}
                        stores={stores}
                        selectedIssueIds={issueIdsInSection}
                        onSelect={async (issueId) => {
                            if (!section) return

                            if (issueIdsInSection.has(issueId)) {
                                await stores.issues.deleteInstance.run(
                                    issueId,
                                    section,
                                )
                            } else {
                                await stores.issues.createInstance.run(
                                    issueId,
                                    section,
                                )
                            }
                        }}
                    />
                )}
            </Modal>
        )
    },
)

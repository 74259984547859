import { action, makeObservable, observable, runInAction } from 'mobx'
import type { APIParser, APIParserRaw } from '../types'
import { rawParserToParser } from '../types'
import { apiClient } from '../util/api'

interface APIGetParsersResponse {
    total: number
    parsers: APIParserRaw[]
}

export class ParserStore {
    public parsersById: Record<string, APIParser> = {}
    public parserIds: string[] = []

    constructor() {
        makeObservable(this, {
            parsersById: observable,
            parserIds: observable,
            fetchParsers: action,
        })
    }

    private isFetchingParsers = false

    public async fetchParsers() {
        if (this.isFetchingParsers) return

        this.isFetchingParsers = true

        try {
            const parsers =
                await apiClient.get<APIGetParsersResponse>('/parser')

            const parsersById: Record<string, APIParser> = {}
            const parserIds: string[] = []

            parsers.data.parsers.forEach((parser) => {
                parsersById[parser.id] = rawParserToParser(parser)
                parserIds.push(parser.id)
            })

            runInAction(() => {
                this.parsersById = parsersById
                this.parserIds = parserIds
            })
        } finally {
            this.isFetchingParsers = false
        }
    }
}

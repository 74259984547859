import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, NavLink, useNavigate } from 'react-router-dom'
import 'reset-css'
import './index.css'
import { IconContext } from 'react-icons'
import { MdMenu } from 'react-icons/md'
import { API_TOKEN_KEY } from '../../util/api'

export interface NavLinkFnProps {
    isActive: boolean
    isPending: boolean
}

const Sidebar = () => {
    const [menuIsExpanded, setMenuIsExpanded] = useState(true)
    const className = useCallback(({ isActive }: NavLinkFnProps) => {
        return isActive ? 'active' : ''
    }, [])

    const onMenuClick = useCallback(() => {
        setMenuIsExpanded((current) => !current)
    }, [])

    return (
        <nav className={`sidebar ${menuIsExpanded ? 'expanded' : 'condensed'}`}>
            <button className="menu-button" onClick={onMenuClick}>
                <IconContext.Provider value={{ color: 'white', size: '1.5em' }}>
                    <MdMenu />
                </IconContext.Provider>
            </button>

            <ul className="items-container">
                <li>
                    <NavLink to="/organizations" className={className}>
                        Organizations
                    </NavLink>
                </li>
                {/* <li><NavLink to="/parsers" className={className}>Parsers</NavLink></li> */}
                <li>
                    <NavLink to="/users" className={className}>
                        Users
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/data-sets" className={className}>
                        Data sets
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/models" className={className}>
                        Models
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/documents" className={className}>
                        Documents
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/issues" className={className}>
                        Issues
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/prompts" className={className}>
                        Prompts
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/test-runs" className={className}>
                        Test runs
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/knowledge-articles" className={className}>
                        Knowledge articles
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/tasks" className={className}>
                        Tasks
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}

export const RootPage: FC = () => {
    const [token] = useState(() => localStorage.getItem(API_TOKEN_KEY))
    const navigate = useNavigate()

    useEffect(() => {
        if (!token) {
            navigate('/login')
        }
    }, [token, navigate])

    if (!token) {
        return null
    }

    return (
        <div className="root-container">
            <Sidebar />

            <div className="root-content">
                <Outlet />
            </div>
        </div>
    )
}

import type { APITestRunQuestion } from '@visorpro/client'
import type { APICreateOrCopyTestRunRequest } from '@visorpro/client/dist/RestClient/services/TestRunService'
import type { APITestRun } from '@visorpro/client/dist/types/APITestRun'
import { makeObservable, observable, runInAction } from 'mobx'
import pMap from 'p-map'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import { ObservableKeyedTask } from '../util/observable-keyed-task'
import { ObservableTask } from '../util/observable-task'
import type { Stores } from '../util/store'

const TEST_RUN_PROCESS_CONCURRENCY = 10

const defaultPagination = {
    offset: 0,
    limit: 100,
}

export class TestRunsStore {
    public testRunsById: Record<string, APITestRun> = {}
    public testRunIdsByDocumentId: Record<string, string[]> = {}
    public testRunIdsByDataSetId: Record<string, string[]> = {}
    public testRunQuestionsById: Record<string, APITestRunQuestion> = {}
    public testRunIds: string[] = []

    constructor() {
        makeObservable(this, {
            testRunsById: observable,
            testRunIdsByDocumentId: observable,
            testRunIdsByDataSetId: observable,
        })
    }

    public getPendingTestRuns = () => {
        const pendingTestRuns: APITestRun[] = []

        this.testRunIds.forEach((testRunId) => {
            const testRun = this.testRunsById[testRunId]

            const isPending = testRun.test_run_questions?.some((question) => {
                return question.acceptance_criteria.some(
                    (item) => item.success === null,
                )
            })

            if (isPending) {
                pendingTestRuns.push(testRun)
            }
        })

        return pendingTestRuns
    }

    public processTestRuns = new ObservableTask(async () => {
        await pMap(
            this.getPendingTestRuns(),
            async (testRun) => {
                await this.processTestRun.run(testRun.id)
            },
            {
                concurrency: TEST_RUN_PROCESS_CONCURRENCY,
            },
        )
    })

    public processTestRun = new ObservableKeyedTask(
        async (testRunId: string) => {
            await visorPRORestClient.testRun.processTestRun(testRunId)
            await this.getTestRunById.run(testRunId)
        },
    )

    public copyTestRuns = new ObservableTask(
        async (
            testRunIds: string[],
            config?: APICreateOrCopyTestRunRequest,
        ) => {
            const testRuns = await pMap(
                testRunIds,
                async (testRunId) => {
                    return visorPRORestClient.testRun.copyTestRun(
                        testRunId,
                        config,
                    )
                },
                { concurrency: 5 },
            )

            runInAction(() => {
                const testRunIds = this.addTestRuns(testRuns)
                this.testRunIds = testRunIds.concat(this.testRunIds)
            })
        },
    )

    public createTestRun = new ObservableTask<string | undefined>(
        async (documentId: string, config: APICreateOrCopyTestRunRequest) => {
            try {
                const testRun = await visorPRORestClient.testRun.createTestRun(
                    documentId,
                    config,
                )

                runInAction(() => {
                    this.addTestRuns([testRun])
                })

                return testRun.id
            } catch (e) {
                toast.error(
                    `Failed to create test question: ${(e as Error).message}`,
                )
            }
        },
    )

    public createDataSetTestRun = new ObservableTask<string | undefined>(
        async (dataSetId: string) => {
            try {
                const dataSetTestRun =
                    await visorPRORestClient.testRun.createDataSetTestRun(
                        dataSetId,
                    )

                runInAction(() => {
                    const testRunsById: Record<string, APITestRun> = {}
                    const dataSetTestRunIds = new Set(
                        this.testRunIdsByDataSetId[dataSetId] ?? [],
                    )

                    dataSetTestRun.test_runs?.forEach((testRun) => {
                        testRunsById[testRun.id] = testRun
                        dataSetTestRunIds.add(testRun.id)
                    })

                    this.testRunsById = {
                        ...this.testRunsById,
                        ...testRunsById,
                    }

                    this.testRunIdsByDataSetId = {
                        ...this.testRunIdsByDataSetId,
                        [dataSetId]: Array.from(dataSetTestRunIds),
                    }
                })

                return dataSetTestRun.id
            } catch (e) {
                toast.error(
                    `Failed to create test question: ${(e as Error).message}`,
                )
            }
        },
    )

    public didFetchTestRuns = false

    public getTestRuns = new ObservableTask(async () => {
        try {
            const testRuns = await visorPRORestClient.testRun.getTestRuns(defaultPagination)

            runInAction(() => {
                const testRunIds = this.addTestRuns(testRuns.items)
                this.testRunIds = testRunIds
            })
        } catch (e) {
            toast.error(`Failed to get test runs: ${(e as Error).message}`)
        }
    })

    public getTestRunsIfNeeded() {
        if (!this.didFetchTestRuns) {
            this.getTestRuns.run()
            this.didFetchTestRuns = true
        }
    }

    public getTestRunsByDocumentId = new ObservableTask(
        async (documentId: string) => {
            try {
                const testRuns = await visorPRORestClient.testRun.getTestRuns({
                    document_id: documentId,
                    ...defaultPagination,
                })

                runInAction(() => {
                    this.addTestRuns(testRuns.items)
                })
            } catch (e) {
                toast.error(`Failed to get test runs: ${(e as Error).message}`)
            }
        },
    )

    public getDataSetTestRuns = new ObservableTask(
        async (dataSetId: string) => {
            try {
                const testRuns = await visorPRORestClient.testRun.getTestRuns({
                    data_set_id: dataSetId,
                    ...defaultPagination,
                })

                runInAction(() => {
                    const testRunsById: Record<string, APITestRun> = {}
                    const dataSetTestRunIds = new Set<string>()

                    testRuns.items.forEach((testRun) => {
                        testRunsById[testRun.id] = testRun
                        dataSetTestRunIds.add(testRun.id)
                    })

                    this.testRunsById = {
                        ...this.testRunsById,
                        ...testRunsById,
                    }

                    this.testRunIdsByDataSetId = {
                        ...this.testRunIdsByDataSetId,
                        [dataSetId]: Array.from(dataSetTestRunIds),
                    }
                })
            } catch (e) {
                toast.error(`Failed to get test runs: ${(e as Error).message}`)
            }
        },
    )

    public getTestRunById = new ObservableKeyedTask(
        async (testRunId: string) => {
            try {
                const testRun =
                    await visorPRORestClient.testRun.getTestRunById(testRunId)

                runInAction(() => {
                    this.addTestRuns([testRun])
                })
            } catch (e) {
                toast.error(`Failed to get test runs: ${(e as Error).message}`)
            }
        },
    )

    private addTestRuns(testRuns: APITestRun[]) {
        const testRunIds: string[] = []
        const testRunsById: Record<string, APITestRun> = {}
        const testRunIdsByDocumentId: Record<string, Set<string>> = {}
        const testRunQuestionsById: Record<string, APITestRunQuestion> = {}

        Object.keys(this.testRunIdsByDocumentId).forEach((documentId) => {
            testRunIdsByDocumentId[documentId] = new Set(
                this.testRunIdsByDocumentId[documentId],
            )
        })

        testRuns.forEach((testRun) => {
            testRunIds.push(testRun.id)

            // test run
            testRunsById[testRun.id] = testRun

            // documents
            if (!testRunIdsByDocumentId[testRun.document_id]) {
                testRunIdsByDocumentId[testRun.document_id] = new Set()
            }

            testRunIdsByDocumentId[testRun.document_id].add(testRun.id)

            // questions
            testRun.test_run_questions?.forEach((question) => {
                testRunQuestionsById[question.id] = question
            })
        })

        this.testRunsById = {
            ...this.testRunsById,
            ...testRunsById,
        }
        this.testRunIdsByDocumentId = Object.fromEntries(
            Object.entries(testRunIdsByDocumentId).map(([documentId, ids]) => [
                documentId,
                Array.from(ids),
            ]),
        )
        this.testRunQuestionsById = {
            ...testRunQuestionsById,
            ...this.testRunQuestionsById,
        }

        return testRunIds
    }
}

export const useDocumentTestRuns = (stores: Stores, documentId: string) => {
    useEffect(() => {
        if (!stores.testRuns.testRunIdsByDocumentId[documentId]) {
            stores.testRuns.getTestRunsByDocumentId.run(documentId)
        }
    }, [documentId, stores])

    return useMemo(() => {
        const ids = stores.testRuns.testRunIdsByDocumentId[documentId] ?? []
        return ids.map((testRunId) => stores.testRuns.testRunsById[testRunId])
    }, [
        documentId,
        stores.testRuns.testRunIdsByDocumentId,
        stores.testRuns.testRunsById,
    ])
}

export const useDataSetTestRuns = (stores: Stores, dataSetId: string) => {
    useEffect(() => {
        if (!stores.testRuns.testRunIdsByDataSetId[dataSetId]) {
            stores.testRuns.getDataSetTestRuns.run(dataSetId)
        }
    }, [dataSetId, stores])

    return useMemo(() => {
        return (
            stores.testRuns.testRunIdsByDataSetId[dataSetId]?.map(
                (testRunId) => stores.testRuns.testRunsById[testRunId],
            ) ?? []
        )
    }, [
        dataSetId,
        stores.testRuns.testRunIdsByDataSetId,
        stores.testRuns.testRunsById,
    ])
}

export const useTestRun = (
    stores: Stores,
    testRunId: string,
): APITestRun | undefined => {
    useEffect(() => {
        if (!stores.testRuns.testRunsById[testRunId]) {
            stores.testRuns.getTestRunById.run(testRunId)
        }
    }, [testRunId, stores])

    return useMemo(() => {
        return stores.testRuns.testRunsById[testRunId]
    }, [testRunId, stores.testRuns.testRunsById])
}

export const useTestRuns = (stores: Stores) => {
    useEffect(() => {
        stores.testRuns.getTestRunsIfNeeded()
    }, [stores])

    return useMemo(() => {
        return stores.testRuns.testRunIds.map(
            (testRunId) => stores.testRuns.testRunsById[testRunId],
        )
    }, [stores.testRuns.testRunsById, stores.testRuns.testRunIds])
}

import { makeObservable, observable, runInAction } from 'mobx'

type AsyncFunction = (...args: any[]) => Promise<void>

// Similar to ObservableTask, but allows multiple tasks to be run at once,
// as long as they have different keys.
export class ObservableKeyedTask<T extends AsyncFunction> {
    constructor(
        private readonly fn: T,
        private readonly generateKey: (...args: Parameters<T>) => string = (
            ...args
        ) => args.map(String).join('-'),
    ) {
        makeObservable(this, {
            runningKeys: observable,
        })
    }

    public runningKeys: Record<string, true> = {}

    public isRunning(key: string) {
        return this.runningKeys[key]
    }

    public run = async (...args: Parameters<T>) => {
        const key = this.generateKey(...args)

        if (this.runningKeys[key]) return

        runInAction(() => {
            this.runningKeys[key] = true
        })

        try {
            await this.fn(...args)
        } finally {
            runInAction(() => {
                delete this.runningKeys[key]
            })
        }
    }
}

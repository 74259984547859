import { makeObservable, observable, runInAction } from 'mobx'
import { useEffect } from 'react'
import type { APIUser, APIUserRaw } from '@visorpro/client'

import { visorPRORestClient } from '../util/api'
import { ObservableTask } from '../util/observable-task'

export interface APIGetUsersResponse {
    total: number
    items: APIUserRaw[]
}

export class UserStore {
    public usersById: Record<string, APIUser> = {}
    public isFetched = false

    constructor() {
        makeObservable(this, {
            usersById: observable,
        })
    }

    public get isFetching() {
        return this.fetchUsers.isRunning
    }

    public get isUpdating() {
        return (
            this.addOrganizationMemberships.isRunning ||
            this.removeOrganizationMemberships.isRunning
        )
    }

    public fetchUsers = new ObservableTask(async () => {
        const response = await visorPRORestClient.user.getAll()
        const users = response.items
        const usersById: Record<string, APIUser> = {}
        users.forEach((user) => {
            usersById[user.id] = user
        })

        runInAction(() => {
            this.usersById = usersById
            this.isFetched = true
        })
    })

    public addOrganizationMemberships = new ObservableTask(
        async (userId: string, organizationIds: string[]) => {
            const memberships = await Promise.all(
                organizationIds.map((organizationId) =>
                    visorPRORestClient.organization.createMembership(
                        organizationId,
                        userId,
                    ),
                ),
            )

            runInAction(() => {
                const user = this.usersById[userId]
                const newMemberships = user.memberships ?? []
                newMemberships.push(...memberships)
                this.usersById[userId].memberships = newMemberships
            })
        },
    )

    public removeOrganizationMemberships = new ObservableTask(
        async (userId: string, organizationIds: string[]) => {
            await Promise.all(
                organizationIds.map((organizationId) =>
                    visorPRORestClient.organization.deleteMembership(
                        organizationId,
                        userId,
                    ),
                ),
            )

            runInAction(() => {
                const user = this.usersById[userId]
                const memberships = user.memberships ?? []
                const newMemberships = memberships.filter(
                    (membership) =>
                        !organizationIds.includes(membership.organization!.id),
                )
                this.usersById[userId].memberships = newMemberships
            })
        },
    )
}

export const useUserById = (store: UserStore, userId: string) => {
    useEffect(() => {
        // fixme: we shouldn't have to fetch every single user for this
        if (!store.isFetched) {
            void store.fetchUsers.run()
        }
    }, [store])

    return store.usersById[userId]
}

export const useUsers = (store: UserStore) => {
    useEffect(() => {
        if (!store.isFetched) {
            void store.fetchUsers.run()
        }
    }, [store, store.isFetched])

    return Object.values(store.usersById)
}

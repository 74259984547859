import { makeObservable, observable, runInAction } from 'mobx'
import type { APIDataSet } from '../../types'

export class DataSetTableStore {
    // mobx can be strange with sets
    public selectedDataSetIds: Record<string, true> = {}

    constructor() {
        makeObservable(this, {
            selectedDataSetIds: observable,
        })
    }

    public clearSelection = () => {
        runInAction(() => {
            this.selectedDataSetIds = {}
        })
    }

    public selectAll = (dataSets: APIDataSet[]) => {
        runInAction(() => {
            const newSelection: Record<string, true> = {}

            for (const dataSet of dataSets) {
                newSelection[dataSet.id] = true
            }

            this.selectedDataSetIds = newSelection
        })
    }

    public setDataSetSelection = (dataSetId: string, value: boolean) => {
        runInAction(() => {
            if (value) {
                this.selectedDataSetIds[dataSetId] = true
            } else {
                delete this.selectedDataSetIds[dataSetId]
            }
        })
    }
}

import type { APIProductManufacturer } from '@visorpro/client'
import { makeObservable, observable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import { ObservableTask } from '../util/observable-task'
import type { Stores } from '../util/store'

interface APICreateManufacturerRequest {
    name: string
}

export class ManufacturerStore {
    public didFetchManufacturers = false
    public manufacturersById: Record<string, APIProductManufacturer> = {}
    public manufacturerIds: string[] = []

    constructor(private readonly stores: Stores) {
        makeObservable(this, {
            manufacturersById: observable,
            manufacturerIds: observable,
        })
    }

    public getManufacturers = new ObservableTask(async () => {
        try {
            const response = await visorPRORestClient.manufacturer.get()

            const manufacturers: Record<string, APIProductManufacturer> = {}
            const manufacturerIds: string[] = []

            response.items.forEach((manufacturer) => {
                manufacturers[manufacturer.id] = manufacturer
                manufacturerIds.push(manufacturer.id)
            })

            runInAction(() => {
                this.manufacturersById = manufacturers
                this.manufacturerIds = manufacturerIds
                this.didFetchManufacturers = true
            })
        } catch (e) {
            toast.error(
                `Failed to fetch manufacturers: ${(e as Error).message}`,
            )
        }
    })

    public create = new ObservableTask<string | undefined>(
        async (input: APICreateManufacturerRequest) => {
            try {
                const manufacturer =
                    await visorPRORestClient.manufacturer.create(input)

                runInAction(() => {
                    this.manufacturersById[manufacturer.id] = manufacturer
                    this.manufacturerIds = [
                        manufacturer.id,
                        ...this.manufacturerIds,
                    ]
                })

                return manufacturer.id
            } catch (e) {
                toast.error(
                    `Failed to create manufacturer: ${(e as Error).message}`,
                )
            }
        },
    )

    public addManufacturers(manufacturers: APIProductManufacturer[]) {
        const copy = {
            ...this.manufacturersById,
        }

        manufacturers.forEach((manufacturer) => {
            copy[manufacturer.id] = manufacturer
        })

        this.manufacturersById = copy
    }
}

export const useManufacturers = (stores: Stores) => {
    useEffect(() => {
        if (!stores.manufacturers.didFetchManufacturers) {
            void stores.manufacturers.getManufacturers.run()
        }
    }, [stores.manufacturers])

    return useMemo(() => {
        const manufacturers: APIProductManufacturer[] = Object.values(
            stores.manufacturers.manufacturersById,
        )
        manufacturers.sort((a, b) => a.name.localeCompare(b.name))
        return manufacturers
    }, [stores.manufacturers.manufacturersById])
}

export const useManufacturerById = (id: string | undefined, stores: Stores) => {
    useEffect(() => {
        if (!stores.manufacturers.didFetchManufacturers) {
            void stores.manufacturers.getManufacturers.run()
        }
    }, [stores.manufacturers])

    if (id) {
        return stores.manufacturers.manufacturersById[id]
    }
}

import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { DocumentsTable } from '../../../../components/documents-table'
import type { Stores } from '../../../../util/store'
import { useDataSets } from '../../../../stores/data-sets-store'
import { useDataSetDocuments } from '../../../../stores/data-set-documents-store'
import { useModels } from '../../../../stores/models-store'
import { type APIUpdateDocumentRequest } from '../../../../stores/documents'

export interface DataSetDocumentsProps {
    stores: Stores
}

export const DataSetDocuments = observer(
    ({ stores }: DataSetDocumentsProps) => {
        const { dataSetId } = useParams()

        if (!dataSetId) {
            return null
        }

        const dataSets = useDataSets(stores)
        const models = useModels(stores)
        const documents = useDataSetDocuments(stores, dataSetId)

        const setEnabled = useCallback((documentId: string, isEnabled: boolean) => {
            void stores.dataSetDocuments.setDocumentEnabled.run(documentId, dataSetId, isEnabled)
        }, [dataSetId, stores.dataSetDocuments.setDocumentEnabled])

        const updateDocument = useCallback((documentId: string, body: APIUpdateDocumentRequest) => {
            void stores.dataSetDocuments.updateDocument.run(documentId, dataSetId, body)
        }, [dataSetId, stores.dataSetDocuments.updateDocument])

        const setModels = useCallback(async (documentId: string, addedModelIds: string[], removedModelIds: string[]) => {
            await Promise.all([
                stores.dataSetDocuments.addDocumentToModels.run(documentId, addedModelIds),
                stores.dataSetDocuments.removeDocumentFromModels.run(documentId, removedModelIds),
            ])
        }, [stores.dataSetDocuments.addDocumentToModels, stores.dataSetDocuments.removeDocumentFromModels])

        return (
            <DocumentsTable
                documents={documents}
                dataSets={dataSets}
                models={models}
                documentUploads={stores.documentUploads.getDataSetUploads(dataSetId)}
                isLoading={stores.dataSetDocuments.isFetching && !stores.dataSetDocuments.isFetched}
                showProgressBars={stores.dataSetDocuments.isUpdating}
                rowCount={documents.length}
                updateDocumentEnabled={setEnabled}
                updateDocument={updateDocument}
                setModels={setModels}
            />
        )
    },
)

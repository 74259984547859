import {
    RawTypeConverter,
    type APIDocumentType,
    type APIDocument,
    type APIDocumentRaw,
    type APIQueueTaskStatus,
} from '@visorpro/client'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import {
    apiClient,
    visorPROPipelineClient,
    visorPRORestClient,
} from '../util/api'
import { ObservableTask } from '../util/observable-task'
import type { Stores } from '../util/store'

export interface UpdateDocumentVersionBody {
    is_enabled: boolean
}

export interface APIUpdateDocumentRequest {
    original_filename?: string
    title?: string
    type?: APIDocumentType
    file_ext?: string
}

export interface APIGetDocumentsRequest {
    title?: string
    data_set_ids?: string[]
    model_ids?: string[]
    offset?: number
    limit?: number
}

export interface APIDocumentExtended extends APIDocument {
    task_status: APIQueueTaskStatus
}

interface APIGetDocumentsResponse {
    total: number
    items: APIDocumentRaw[]
}

export class DocumentsStore {
    public documentsById: Record<string, APIDocument> = {}
    public documentTaskStatus: Record<string, APIQueueTaskStatus> = {}
    public totalDocuments: number = 0

    constructor(private readonly stores: Stores) {
        makeObservable(this, {
            documentsById: observable,
            documentTaskStatus: observable,
            totalDocuments: observable,
        })
    }

    public get isFetching() {
        return this.getDocuments.isRunning
    }

    public get isUpdating() {
        return (
            this.updateDocument.isRunning ||
            this.setDocumentEnabled.isRunning ||
            this.addDocumentToDataSet.isRunning ||
            this.addDocumentToDataSets.isRunning ||
            this.removeDocumentFromDataSet.isRunning ||
            this.removeDocumentFromDataSets.isRunning ||
            this.removeDocumentFromModels.isRunning ||
            this.removeDocumentsFromModels.isRunning
        )
    }

    public get documents() {
        return computed(() => Object.values(this.documentsById)).get()
    }

    public get documentsExtended() {
        return computed(() =>
            Object.values(this.documentsById).map((document) => ({
                ...document,
                taskStatus: this.documentTaskStatus[document.id],
            })),
        ).get()
    }

    public getDocuments = new ObservableTask(
        async (options?: APIGetDocumentsRequest) => {
            const documents = await apiClient.get<APIGetDocumentsResponse>(
                '/document',
                {
                    params: {
                        limit: 100,
                        ...options,
                    },
                },
            )

            const documentsById: Record<string, APIDocument> = {}
            documents.data.items.forEach((documentRaw) => {
                const document = RawTypeConverter.fixDocument(documentRaw)
                documentsById[document.id] = document
            })

            runInAction(() => {
                this.documentsById = documentsById
                this.totalDocuments = documents.data.total
            })

            const documentIds = Object.keys(documentsById)
            await this.getTasksStatus.run(documentIds)
        },
    )

    public getDocumentById = new ObservableTask(async (documentId: string) => {
        try {
            const document =
                await visorPRORestClient.document.getAdminDocumentById(
                    documentId,
                )

            runInAction(() => {
                this.documentsById[documentId] = document
            })
        } catch (e) {
            toast.error(`Failed to fetch document: ${(e as Error).message}`)
        }
    })

    public getTasksStatus = new ObservableTask(
        async (documentId: string | string[]) => {
            if (!Array.isArray(documentId)) {
                documentId = [documentId]
            }

            const chunks = _.chunk(documentId, 1000)

            const responses = await Promise.all(
                chunks.map((chunk) =>
                    visorPROPipelineClient.tasksStatus.getTasksStatus({
                        document_id: chunk,
                    }),
                ),
            )

            runInAction(() => {
                this.documentTaskStatus = responses.reduce((acc, response) => {
                    return {
                        ...acc,
                        ...response,
                    }
                }, {})
            })
        },
    )

    public updateDocumentVersion = new ObservableTask(
        async (
            documentId: string,
            versionId: string,
            body: UpdateDocumentVersionBody,
        ) => {
            try {
                const response =
                    await visorPRORestClient.document.updateDocumentVersion(
                        versionId,
                        {
                            is_enabled: body.is_enabled,
                        },
                    )

                runInAction(() => {
                    const document = this.documentsById[documentId]

                    if (document) {
                        document.versions = document.versions?.map(
                            (version) => {
                                if (version.id === versionId) {
                                    return response
                                }

                                return version
                            },
                        )
                    }
                })
            } catch (e) {
                toast.error(`Failed to fetch document: ${(e as Error).message}`)
            }
        },
    )

    public setDocumentEnabled = new ObservableTask(
        async (documentId: string, isEnabled: boolean) => {
            try {
                const document =
                    await visorPRORestClient.document.updateDocument(
                        documentId,
                        {
                            is_enabled: isEnabled,
                        },
                    )

                runInAction(() => {
                    this.documentsById[documentId] = document
                })
            } catch (e) {
                toast.error(
                    `Failed to set document enabled: ${(e as Error).message}`,
                )
            }
        },
    )

    public updateDocument = new ObservableTask(
        async (documentId: string, options: APIUpdateDocumentRequest) => {
            const document = await visorPRORestClient.document.updateDocument(
                documentId,
                options,
            )

            runInAction(() => {
                this.documentsById[document.id] = document
            })
        },
    )

    public addDocumentToDataSet = new ObservableTask(
        async (documentId: string, dataSetId: string) => {
            await this.addDocumentToDataSets.run(documentId, [dataSetId])
        },
    )

    public addDocumentToDataSets = new ObservableTask(
        async (documentId: string, dataSetIds: string[]) => {
            await Promise.all(
                dataSetIds.map((dataSetId) =>
                    apiClient.post(
                        `/data-set/${dataSetId}/document/${documentId}`,
                    ),
                ),
            )

            runInAction(() => {
                const document = this.documentsById[documentId]
                const dataSets = document.data_sets ?? []
                const concatenatedDataSets = dataSetIds.map(
                    (dataSetId) => this.stores.dataSets.dataSetsById[dataSetId],
                )
                const newDataSets = dataSets.concat(...concatenatedDataSets)
                this.documentsById[documentId] = {
                    ...document,
                    data_sets: newDataSets,
                }
            })
        },
    )

    public removeDocumentFromDataSet = new ObservableTask(
        async (documentId: string, dataSetId: string) => {
            await this.removeDocumentFromDataSets.run(documentId, [dataSetId])
        },
    )

    public removeDocumentFromDataSets = new ObservableTask(
        async (documentId: string, dataSetIds: string[]) => {
            await Promise.all(
                dataSetIds.map((dataSetId) =>
                    apiClient.delete(
                        `/data-set/${dataSetId}/document/${documentId}`,
                    ),
                ),
            )

            runInAction(() => {
                const document = this.documentsById[documentId]
                const dataSet = document.data_sets ?? []
                const newDataSet = dataSet.filter(
                    (dataSet) => !dataSetIds.includes(dataSet.id),
                )
                this.documentsById[documentId] = {
                    ...document,
                    data_sets: newDataSet,
                }
            })
        },
    )

    public addDocumentToModels = new ObservableTask(
        async (documentId: string, modelIds: string[]) => {
            await Promise.all(
                modelIds.map((modelId) =>
                    visorPRORestClient.model.addDocumentsToProductModel(
                        modelId,
                        [documentId],
                    ),
                ),
            )

            runInAction(() => {
                const document = this.documentsById[documentId]
                const d2m = document.document_to_product_model || []
                const newD2M = d2m.concat(
                    modelIds.map((modelId) => ({
                        product_model: this.stores.models.modelsById[modelId],
                    })),
                )
                this.documentsById[documentId] = {
                    ...document,
                    document_to_product_model: newD2M,
                }
            })
        },
    )

    public removeDocumentFromModels = new ObservableTask(
        async (documentId: string, modelIds: string[]) => {
            await this.removeDocumentsFromModels.run([documentId], modelIds)
        },
    )

    public removeDocumentsFromModels = new ObservableTask(
        async (documentIds: string[], modelIds: string[]) => {
            await Promise.all(
                modelIds.map((modelId) =>
                    visorPRORestClient.model.removeDocumentsFromProductModel(
                        modelId,
                        documentIds,
                    ),
                ),
            )

            runInAction(() => {
                documentIds.forEach((documentId) => {
                    const document = this.documentsById[documentId]
                    const d2m = document.document_to_product_model || []
                    const newD2M = d2m.filter(
                        (r) => !modelIds.includes(r.product_model.id),
                    )
                    this.documentsById[documentId] = {
                        ...document,
                        document_to_product_model: newD2M,
                    }
                })
            })
        },
    )

    public addDocument = (document: APIDocument) => {
        this.documentsById[document.id] = document
    }
}

export const useDocumentById = (
    stores: Stores,
    documentId: string,
): APIDocument | undefined => {
    useEffect(() => {
        if (!stores.documents.documentsById[documentId]) {
            void stores.documents.getDocumentById.run(documentId)
        }
    }, [
        documentId,
        stores.documents.documentsById,
        stores.documents.getDocumentById,
    ])

    return stores.documents.documentsById[documentId]
}

export const useDocuments = (
    stores: Stores,
    options?: APIGetDocumentsRequest,
    extended?: boolean,
) => {
    useEffect(() => {
        void stores.documents.getDocuments.run(options)
    }, [options, stores.documents.getDocuments])

    const documents = useMemo(() => {
        return computed(() => {
            return Object.values(stores.documents.documentsById)
        })
    }, [stores.documents.documentsById]).get()

    const documentsExtended = useMemo(() => {
        return computed(() => {
            return Object.values(stores.documents.documentsById).map(
                (document) => ({
                    ...document,
                    task_status:
                        stores.documents.documentTaskStatus[document.id],
                }),
            )
        })
    }, [
        stores.documents.documentTaskStatus,
        stores.documents.documentsById,
    ]).get()

    if (extended) {
        return documentsExtended
    }
    return documents
}

import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { v4 } from 'uuid'
import type { Stores } from '../../util/store'
import { Modal } from '../modal'
import { FormSeparator } from '../modals/creation/form-separator'
import { FormTextInput } from '../modals/creation/form-text-input'
import { TextButton } from '../text-button'
import { AcceptanceCriteriaList } from './acceptance-criteria-list'
import { QuestionFormStore } from './question-form-store'

export interface NewDocumentModalProps {
    testQuestionId?: string
    documentIds?: string[]
    dataSetIds?: string[]
    stores: Stores
    anchor: HTMLElement
    onClose(): void
}

export const QuestionModal: FC<NewDocumentModalProps> = ({
    testQuestionId,
    documentIds,
    dataSetIds,
    stores,
    anchor,
    onClose,
}) => {
    const [formStore] = useState(() => {
        if (testQuestionId) {
            const testQuestion =
                stores.testQuestions.testQuestionsById[testQuestionId]
            return new QuestionFormStore(
                testQuestion.text,
                testQuestion.acceptance_criteria.map((item) => ({
                    id: item.id,
                    key: item.id,
                    text: item.text,
                })),
            )
        }

        return new QuestionFormStore('', [{ key: v4(), text: '' }])
    })

    const submit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault()

            if (testQuestionId) {
                const didUpdate = await formStore.update(stores, testQuestionId)

                if (didUpdate) {
                    onClose()
                }
            } else {
                const didCreate = await formStore.create(stores, {
                    document_ids: documentIds,
                    data_set_ids: dataSetIds,
                })

                if (didCreate) {
                    onClose()
                }
            }
        },
        [formStore, stores, onClose, documentIds, dataSetIds, testQuestionId],
    )

    return (
        <Modal onClose={onClose} style={{ width: '600px' }} anchor={anchor}>
            <form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 1rem 1rem',
                }}
                onSubmit={submit}>
                <span className="color-dimmed">Question</span>

                <QuestionTextInput
                    store={formStore}
                    style={{
                        marginTop: '0.5rem',
                    }}
                />

                <span
                    className="color-dimmed"
                    style={{
                        marginTop: '1rem',
                    }}>
                    A correct answer should...
                </span>

                <AcceptanceCriteriaList
                    store={formStore}
                    style={{
                        marginTop: '0.5rem',
                    }}
                />

                <FormSeparator />

                <TextButton
                    onClick={submit}
                    disabled={stores.testQuestions.createTestQuestion.isRunning}
                    disabledContent={
                        testQuestionId ? 'Updating...' : 'Creating...'
                    }>
                    {testQuestionId ? 'Update' : 'Create'}
                </TextButton>
            </form>
        </Modal>
    )
}

interface QuestionTextInputProps {
    store: QuestionFormStore
    style?: React.CSSProperties
}

// small wrapper to avoid re-rendering the entire modal when the text changes
const QuestionTextInput: FC<QuestionTextInputProps> = observer(
    ({ store, style }) => {
        return (
            <FormTextInput
                value={store.text}
                onChange={store.setText}
                placeholder="The bot should be able to answer..."
                style={style}
            />
        )
    },
)

import { Autocomplete, type AutocompleteProps, TextField, type AutocompleteRenderInputParams, type TextFieldProps } from '@mui/material';
import { Children, createContext, type CSSProperties, forwardRef, type HTMLAttributes, type ReactElement, type ReactNode, useContext } from "react"
import { FixedSizeList } from 'react-window';

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

export interface SearchableSelectOption {
  label: string
  value: string
}

export interface SearchableSelectProps<T extends SearchableSelectOption> extends Omit<AutocompleteProps<T, true, true, false>, "freeSolo" | "options" | "renderInput"> {
  options: T[]
  rowHeight?: number
  TextFieldProps?: Partial<TextFieldProps>
  renderInput?: (params: AutocompleteRenderInputParams) => ReactElement
}

export const SearchableSelect = <K extends SearchableSelectOption>({ options, rowHeight = 34, TextFieldProps = {}, renderInput, ...props }: SearchableSelectProps<K>) => {
  const renderInputDefault = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} {...TextFieldProps} variant='standard' />
  }

  const ListboxComponent = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const items: ReactNode[] = Children.toArray(children);
    const renderRow = ({ index, style }: { index: number, style: CSSProperties }) => {
      return <div key={index} style={style}>{items[index]}</div>;
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            itemData={items}
            itemSize={rowHeight}
            height={600}
            width="100%"
            outerElementType={OuterElementType}
            innerElementType="ul"
            overscanCount={5}
            itemCount={items.length}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  return (
    <Autocomplete
      disableListWrap
      autoFocus
      fullWidth
      disableClearable
      freeSolo={false}
      options={options}
      inputMode='search'
      renderInput={renderInput || renderInputDefault}
      ListboxComponent={ListboxComponent}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      {...props}
    />
  )
}

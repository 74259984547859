import type {
    APICreatePromptRequest,
    APIUpdatePromptRequest,
} from '@visorpro/client/dist/RestClient/services/PromptService'
import type { APIPrompt } from '@visorpro/client/dist/types/APIPrompt'
import type { APIPromptMessageRole } from '@visorpro/client/dist/types/APIPromptMessage'
import { makeObservable, observable, runInAction } from 'mobx'
import { v4 } from 'uuid'
import { ListStore } from '../../util/list-store'
import type { Stores } from '../../util/store'
import { MessageRole } from './message-list-item'

export interface PromptMessageInput {
    //`id` is only set for items that already exist in the api
    id?: string
    // `key` is used in react list components.
    // defaults to `id` for existing items, and a new uuid for new ones.
    key: string
    role: APIPromptMessageRole
    content: string
}

export class PromptFormStore {
    public promptId?: string
    public name: string
    public promptMessagesStore: ListStore<PromptMessageInput>

    constructor(prompt?: APIPrompt) {
        this.promptId = prompt?.id
        this.name = prompt?.name ?? ''

        let initialItems: PromptMessageInput[]

        if (prompt) {
            initialItems = prompt.messages.map((message) => ({
                id: message.id,
                key: message.id,
                role: message.role,
                content: message.content,
            }))
        } else {
            initialItems = [
                {
                    key: v4(),
                    role: MessageRole.SYSTEM,
                    content: '',
                },
            ]
        }

        this.promptMessagesStore = new ListStore<PromptMessageInput>({
            initialItems,
            createItem: () => ({
                key: v4(),
                role:
                    this.promptMessagesStore.itemKeys.length === 0
                        ? MessageRole.SYSTEM
                        : MessageRole.USER,
                content: '',
            }),
        })

        makeObservable(this, {
            name: observable,
        })
    }

    public itemIsFocused = (key: string) => {
        return this.promptMessagesStore.autoFocusKey === key
    }

    public setName = (name: string) => {
        runInAction(() => {
            this.name = name
        })
    }

    public setMessageRole = (key: string, role: APIPromptMessageRole) => {
        this.promptMessagesStore.updateItem(key, (item) => ({
            ...item,
            role,
        }))
    }

    public setMessageContent = (key: string, content: string) => {
        this.promptMessagesStore.updateItem(key, (item) => ({
            ...item,
            content,
        }))
    }

    public async createOrUpdate(stores: Stores): Promise<string | undefined> {
        if (!this.isValid()) return

        const input: APICreatePromptRequest | APIUpdatePromptRequest = {
            name: this.name.trim(),
            messages: this.promptMessagesStore.getItems().map((item) => ({
                id: item.id,
                role: item.role,
                content: item.content,
            })),
        }

        if (this.promptId) {
            return stores.prompts.updatePrompt.run(this.promptId, input)
        } else {
            return stores.prompts.createPrompt.run(input)
        }
    }

    private isValid() {
        return this.name.trim().length !== 0
    }

    public isRunning(stores: Stores) {
        return (
            stores.prompts.createPrompt.isRunning ||
            stores.prompts.updatePrompt.isRunning
        )
    }
}

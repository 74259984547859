import { type ThemeOptions, createTheme, darken, lighten, MenuItem } from '@mui/material'

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#bd93f9',
        },
        background: {
            default: '#282a36',
            paper: lighten('#282a36', 0.1),
        },
        text: {
            primary: '#f8f8f2',
            secondary: darken('#f8f8f2', 0.2),
        }
    },
    typography: {
        fontFamily: 'JetBrains Mono',
        htmlFontSize: 16,
        fontSize: 14,
    },
    components: {
        MuiTypography: {
            defaultProps: {
                fontSize: 14,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                sx: { fontSize: '14px !important' },
            },
        },
        MuiMenu: {
            defaultProps: {
                sx: {
                    maxHeight: '50vh',
                },
            },
        },
        MuiMenuItem: {
            defaultProps: {
                sx: {
                    fontSize: '14px !important',
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                sx: {
                    fontSize: '14px !important',
                },
                size: 'small',
            },
        },
        MuiChip: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                size: 'small',
                renderOption: (props, option, state, ownerState) => {
                    const label = ownerState.getOptionLabel(option)
                    return <MenuItem {...props} key={state.index} title={label}>{label}</MenuItem>
                },
            },
        },
        MuiListItem: {
            defaultProps: {
                sx: {
                    fontSize: '14px !important',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0.2rem 0.5rem !important',
                    whiteSpace: 'normal !important',
                    wordWrap: 'break-word',
                },
            },
        },
    },
    spacing: 2,
    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
}

export const theme = createTheme(themeOptions);
import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { DataSetsTable } from '../../components/data-sets-table'
import { useModal } from '../../components/modal'
import { SingleTextFieldModal } from '../../components/modals/updates/single-text-field-modal'
import { TextButton } from '../../components/text-button'
import { useDataSets } from '../../stores/data-sets-store'
import type { APIDataSet } from '../../types'
import type { Stores } from '../../util/store'
import { DocumentSectionPreview } from '../documents/section-preview'

export interface DataSetsPageProps {
    stores: Stores
}

export const DataSetsPage = observer(({ stores }: DataSetsPageProps) => {
    const dataSets = useDataSets(stores)

    const createLink = useCallback((dataSet: APIDataSet) => {
        return `/data-sets/${dataSet.id}/documents`
    }, [])

    const createDataSetModal = useModal()

    const createDataSet = useCallback(
        async (_id: string, name: string) => {
            await stores.dataSets.create.run({ name })
            createDataSetModal.close()
        },
        [stores.dataSets.create, createDataSetModal],
    )

    const [documentSectionPreviewRequest, setDocumentSectionPreviewRequest] =
        useState<APIGetRandomSectionsRequest>()

    if (!dataSets) return null

    return (
        <div style={{ padding: '1rem' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                <BreadCrumbs name="Data sets" path={[]} />

                <TextButton onClick={createDataSetModal.open}>
                    Create data set
                </TextButton>
            </div>

            <DataSetsTable
                stores={stores}
                dataSets={dataSets}
                createLink={createLink}
                onReviewStart={setDocumentSectionPreviewRequest}
            />

            {documentSectionPreviewRequest && (
                <DocumentSectionPreview
                    request={documentSectionPreviewRequest}
                    stores={stores}
                    onClose={() => {
                        setDocumentSectionPreviewRequest(undefined)
                    }}
                />
            )}

            {createDataSetModal.state && (
                <SingleTextFieldModal
                    identifier={''}
                    initialName={''}
                    anchor={createDataSetModal.state.anchor}
                    onClose={createDataSetModal.close}
                    onSubmit={createDataSet}
                    isLoading={stores.dataSets.create.isRunning}
                    contentStyle={{ marginTop: 0, width: '500px' }}
                    placeholder="Case IH"
                    placement="bottom-end"
                    submitText="Create"
                    submitTextLoading="Creating..."
                />
            )}
        </div>
    )
})

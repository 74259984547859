import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { PdfThumbnails } from '../../components/pdf-thumbnails'
import { usePdfDocument } from '../../util/pdf'
import { ParserEditorNavbar } from './navbar'

export interface ParserEditorPageProps {}

export const ParserEditorPage: FC<ParserEditorPageProps> = () => {
    const [showPageOverlay, setShowPageOverlay] = useState(false)
    // todo: replace with dynamic url
    const url =
        'https://s3.us-east-1.amazonaws.com/api-prod.visorpro.ai/documents/0069d267bcd93b12b692f4d1c07fb2cd/input.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaCXVzLWVhc3QtMSJIMEYCIQD4iaOW2R2l6wMcrO3BVeE4BAt3P0jofdJbk7pIYU4A6gIhALmF95bgwfoI6KA%2FpnP0tZsvM2jlm%2FZlk8uKgIfHFg81Kv8CCBkQAxoMNDcxMjU2NDcyOTM5IgzI114WYwH9zD9PyBgq3AIadl1XBa8gGlH0swBAuzDSJC9uYbYQAqu0zuOVWZuoO4TAQ1CvDCGQqIFrMgtPn68wGw2KDkOfz78uh3r%2BK7UCtN5GRCjHGUKVtfVm4HqC0Cisycm%2FrkimExmjx3rOuS%2FkDnJLfankMgWXQi4X3NA4WR9QljutLApFuT1v60OYpYXJ6cdmZNt7hgRnOs%2FJK3WEruU2P7Xw7VhW3Eae7RxZrsyyQc%2BXqkpup6K%2B9cxXllU0TibCYht%2FFCfbWhK%2BLb1lD%2BuKwu%2B9Qgoi5hch7SvZk%2FVFkEbrkzdAynLvFuHrKYfaQ7pduzHJgOX5nmz%2FKGDL%2BclqmG4Z9MXYCuwQvczRV40VW%2BkYhS6AhTEO%2B8P0e%2FoGIjmdby9pkcYA1c20%2FvJZopRU3Z0JeL22INyK6LtYlbFy1Bb1lgYyQI5wS755sMzM09X1W1x4rU92K3jQIJFcQadMLp0q%2BluuTxgw0%2Fu2qAY6sgKmrs%2BImzvnM6i83F564l3SnOvS8HpfSJBfo3wo6W3p1aECOVLm4jQek%2FyXQS1a0z6WHzriImCAOwU9RiraH9FY0t5qXD8pLvuTM6dyeySO1visF8Jnky482qeS7kRQKJ5lhuG8OFothyFiNEQHoqVDp7ZUQFZkdV0qqawLN6JEZRCgcii6PVSiNg%2B1ezf0OiX6otGo50GVpCGAW9PV929R1seWsaGhZhiFTgntDfNER%2B3sOq%2FHE8JbbLKXxs5uYv081a1tL%2BbX1uC1nTeI%2FOKuuuuaJwKnbRIxqmMjww4HvIkUDNQcpaZ1VrDXM0oUbrQQCTavSxMxfZzqM3stFfLGTK7Ip6u0ZnMS6oCT%2Fcn74HHDfriZwKkHJSaOaBmnQtPKSfwz5xCOKD58vYftesFOoYA%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230922T161652Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAW3OITDVV7FKFM334%2F20230922%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=c4f4bc14f68a0dc8f7797778669462586d9f30d0e51e2b3e0c7a47bc2493476d'
    const doc = usePdfDocument(url)

    const showPage = useCallback(() => {
        setShowPageOverlay(true)
    }, [])

    return (
        <div
            style={{
                display: 'flex',
            }}>
            <ParserEditorNavbar />

            <div
                style={{
                    padding: '1rem',
                    flexGrow: 1,
                    overflow: 'auto',
                    position: 'relative',
                }}>
                <BreadCrumbs name="Editor" path={[]} />

                <PdfThumbnails doc={doc} onPageClick={showPage} />

                {showPageOverlay && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 350,
                            width: 'calc(100vw - 350px)',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '1rem',
                            boxSizing: 'border-box',
                            gap: '0.5rem',
                        }}
                        onClick={() => setShowPageOverlay(false)}>
                        <div
                            style={{
                                backgroundColor: 'var(--background-color)',
                                height: '100%',
                                width: '100%',
                            }}>
                            <p className="color-foreground">
                                Full page with guides
                            </p>
                        </div>

                        <div
                            style={{
                                backgroundColor: 'var(--background-dark-color)',
                                height: '100%',
                                width: '100%',
                            }}>
                            <p className="color-foreground">Markdown</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { TextButton } from '../../../../components/text-button'
import { useOrganization } from '../../../../stores/organization-store'
import type { Stores } from '../../../../util/store'

export interface OrganizationProfilePageProps {
    stores: Stores
}

export const OrganizationProfilePage: FC<OrganizationProfilePageProps> =
    observer(({ stores }) => {
        const { organizationId } = useParams()
        const organization = useOrganization(stores, organizationId!)
        const fileInputRef = useRef<HTMLInputElement>(null)

        const handleButtonClick = useCallback(() => {
            fileInputRef.current?.click()
        }, [])

        const handleFileChange = useCallback(
            async (event: React.ChangeEvent<HTMLInputElement>) => {
                const files = event.target.files

                if (!files || !files.length) return

                const firstFile = files[0]

                await stores.organizations.uploadLogo.run(
                    organizationId!,
                    firstFile,
                )
            },
            [organizationId, stores.organizations.uploadLogo],
        )

        const logoSource = organization?.logo?.url
            ? `${organization.logo.url}?id=${organization.logo.id}`
            : undefined

        return (
            <div style={{ paddingTop: '1rem' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '1rem',
                    }}>
                    {typeof logoSource === 'string' && (
                        <img src={logoSource} alt="logo" />
                    )}

                    {stores.organizations.uploadLogo.isRunning(
                        organizationId!,
                    ) && (
                        <span
                            className="color-dimmed"
                            style={{ marginTop: '1rem' }}>
                            Uploading...
                        </span>
                    )}

                    <TextButton
                        style={{ marginTop: '1rem' }}
                        onClick={handleButtonClick}>
                        Upload logo
                    </TextButton>
                </div>

                <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </div>
        )
    })

import type { CSSProperties, FC } from 'react'
import { useCallback } from 'react'

export interface FormTextInputProps {
    autoFocus?: boolean
    placeholder?: string
    value: string
    onChange?(value: string): void
    style?: CSSProperties
}

export const FormTextInput: FC<FormTextInputProps> = ({
    autoFocus = true,
    value,
    onChange,
    placeholder,
    style,
}) => {
    const _onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e.target.value)
        },
        [onChange],
    )

    return (
        <input
            className="text-small"
            autoFocus={autoFocus}
            style={{
                width: '100%',
                padding: '0',
                border: 'none',
                boxSizing: 'border-box',
                fontSize: '1rem',
                outline: 'none',
                backgroundColor: 'transparent',
                color: 'var(--foreground-color)',
                ...style,
            }}
            placeholder={placeholder}
            value={value}
            onChange={_onChange}
        />
    )
}

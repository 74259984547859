import { apiClient } from '../util/api'
import type { APIDataSetRaw } from '../types'
import { rawDataSetToDataSet } from '../types'
import { useEffect, useMemo } from 'react'
import type { Stores } from '../util/store'
import { ObservableTask } from '../util/observable-task'
import { makeObservable, observable, runInAction } from 'mobx'

interface APIGetOrganizationDataSetsResponse {
    total: number
    items: APIDataSetRaw[]
}

export class OrganizationDataSetsStore {
    public dataSetIdsByOrganizationId: Record<string, string[] | undefined> = {}

    constructor(private readonly stores: Stores) {
        makeObservable(this, {
            dataSetIdsByOrganizationId: observable,
        })
    }

    public fetchOrganizationDataSets = new ObservableTask(
        async (organizationId: string) => {
            const dataSets =
                await apiClient.get<APIGetOrganizationDataSetsResponse>(
                    `/organization/${organizationId}/data-set`,
                )

            runInAction(() => {
                this.stores.dataSets.addDataSets(
                    dataSets.data.items.map(rawDataSetToDataSet),
                )
                this.dataSetIdsByOrganizationId[organizationId] =
                    dataSets.data.items.map((dataSet) => dataSet.id)
            })
        },
    )

    public fetchOrganizationDataSetsIfNeeded(organizationId: string) {
        if (!this.dataSetIdsByOrganizationId[organizationId]) {
            this.fetchOrganizationDataSets.run(organizationId)
        }
    }

    public addDataSetIdToOrganization(
        organizationId: string,
        dataSetId: string,
    ) {
        this.stores.organizationDataSets.dataSetIdsByOrganizationId[
            organizationId
        ] = [
            dataSetId,
            ...(this.stores.organizationDataSets.dataSetIdsByOrganizationId[
                organizationId
            ] || []),
        ]
    }

    public removeDataSetIdFromOrganization(
        organizationId: string,
        dataSetId: string,
    ) {
        this.stores.organizationDataSets.dataSetIdsByOrganizationId[
            organizationId
        ] = [
            ...(
                this.stores.organizationDataSets.dataSetIdsByOrganizationId[
                    organizationId
                ] || []
            ).filter((id) => id !== dataSetId),
        ]
    }
}

export const useOrganizationDataSets = (
    stores: Stores,
    organizationId: string,
) => {
    useEffect(() => {
        stores.organizationDataSets.fetchOrganizationDataSetsIfNeeded(
            organizationId,
        )
    }, [organizationId, stores])

    return (
        stores.organizationDataSets.dataSetIdsByOrganizationId[
            organizationId
        ]?.map((dataSetId) => stores.dataSets.dataSetsById[dataSetId]) ?? []
    )
}

export const useOrganizationDataSet = (
    stores: Stores,
    organizationId: string,
    dataSetId: string,
) => {
    const dataSets = useOrganizationDataSets(stores, organizationId)

    return useMemo(() => {
        return dataSets.find((dataSet) => dataSet.id === dataSetId)
    }, [dataSets, dataSetId])
}

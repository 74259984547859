import { useEffect } from 'react'
import { makeObservable, observable, runInAction } from 'mobx'
import type { APIQueueTask } from '@visorpro/client'

import type { Stores } from '../util/store'
import { visorPROPipelineClient } from '../util/api'
import { ObservableTask } from '../util/observable-task'
import { type APIGetQueueTaskRequestFilteringParams } from '@visorpro/client/dist/PipelineClient/services'
import _ from 'lodash'

export class QueueTasksStore {
    public tasks: APIQueueTask[] = []
    public totalTasks: number = 0
    public queryParams: APIGetQueueTaskRequestFilteringParams = {}
    public isFetched = false

    constructor() {
        makeObservable(this, {
            tasks: observable,
            totalTasks: observable,
            queryParams: observable,
            isFetched: observable,
        })
    }

    public get isFetching() {
        return this.fetchTasks.isRunning
    }

    public get isUpdating() {
        return false
    }

    public fetchTasks = new ObservableTask(
        async (params?: APIGetQueueTaskRequestFilteringParams) => {
            const response = await visorPROPipelineClient.tasks.getTasks(params)

            runInAction(() => {
                this.tasks = response.items
                this.totalTasks = response.total
                this.queryParams = params || {}
                this.isFetched = true
            })
        },
    )
}

export const useQueueTasks = (
    stores: Stores,
    params?: APIGetQueueTaskRequestFilteringParams,
) => {
    const { queueTasks } = stores

    useEffect(() => {
        if (!_.isEqual(queueTasks.queryParams, params)) {
            void queueTasks.fetchTasks.run(params)
        }
    }, [params, queueTasks, stores.queueTasks.queryParams])

    return queueTasks.tasks
}

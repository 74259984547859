import { type HTMLAttributes, useMemo, type ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { type APIDocument, type APIProductModel, type APIQueueTaskStatus, APIDocumentType } from '@visorpro/client'
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_Updater,
} from 'material-react-table';
import { Switch, Chip, MenuItem, ListItemText, IconButton, Tooltip, type FilterOptionsState, type AutocompleteRenderOptionState, Typography, Box, CircularProgress, type ChipProps, Select, type SelectChangeEvent } from '@mui/material';
import { ErrorOutline, Info, OpenInNew } from '@mui/icons-material';
import _ from 'lodash'
import fuzzysort from 'fuzzysort';
import { darken } from '@mui/material/styles';
import { type DateTime } from 'luxon';
import { formatDate } from '../util/format';
import { type APIUpdateDocumentRequest, type APIGetDocumentsRequest } from '../stores/documents';
import { type APIDataSet } from '../types';
import { type SearchableSelectOption } from './searchable-select';
import { type DocumentUpload } from '../stores/document-upload';
import { useAdminMaterialReactTable, useMultiValueColumnDef } from './table/mui-table';
import { getQueueTaskStatusIcon } from './tasks-table';

export interface DocumentsTableProps {
    documents: APIDocument[]
    dataSets: APIDataSet[]
    models: APIProductModel[]
    documentUploads?: DocumentUpload[]
    isLoading?: boolean
    showProgressBars?: boolean
    rowCount?: number
    pagination?: Pick<APIGetDocumentsRequest, 'offset' | 'limit'>
    filters?: Omit<APIGetDocumentsRequest, 'offset' | 'limit'>
    setPagination?: (pagination: Pick<APIGetDocumentsRequest, 'offset' | 'limit'>) => void
    setFilters?: (filters: Omit<APIGetDocumentsRequest, 'offset' | 'limit'>) => void
    updateDocumentEnabled?: (id: string, isEnabled: boolean) => void
    updateDocument?: (documentId: string, body: APIUpdateDocumentRequest) => void
    setDataSets?: (documentId: string, addedDatasetIds: string[], removedDataSetIds: string[]) => void
    setModels?: (documentId: string, addedModelIds: string[], removedModelIds: string[]) => void
}

interface ModelSelectOption extends SearchableSelectOption {
    manufacturer?: string
    renderedLabel?: ReactNode
    renderedManufacturer?: ReactNode
}

const useModelOptions = (models: APIProductModel[]): ModelSelectOption[] => {
    return useMemo(() => models.map((model) => ({ manufacturer: model.product_manufacturer?.name, label: model.name, value: model.id })), [models]);
}

const useDataSetOptions = (dataSets: APIDataSet[]): SearchableSelectOption[] => {
    return useMemo(() => dataSets.map((dataSet) => ({ label: dataSet.name, value: dataSet.id })), [dataSets]);
}

const renderModelOption = (props: HTMLAttributes<HTMLLIElement>, option: ModelSelectOption, state: AutocompleteRenderOptionState) => (
    <MenuItem value={option.value} {...props} key={state.index}>
        <ListItemText primary={option.renderedLabel || option.label} secondary={option.renderedManufacturer || option.manufacturer} title={option.label} />
    </MenuItem>
)

const filterModelOptions = (options: ModelSelectOption[], { inputValue }: FilterOptionsState<ModelSelectOption>) => {
    if (!inputValue || inputValue == '') return options

    const matchHighlight = (match: string, index: number) => (
        <Box
            component="span"
            sx={{
                backgroundColor: (theme) => darken(theme.palette.warning.dark, 0.25),
                borderRadius: '2px',
                color: (theme) => theme.palette.common.white,
                padding: '2px 1px',
            }}
            key={index}
        >
            {match}
        </Box>
    )
    const input = inputValue.trim().toLowerCase()
    const results = fuzzysort.go(input, options, {
        keys: ['label', 'manufacturer'],
    })
    return results.map((result) => {
        const highlightedLabel = result[0].highlight(matchHighlight)
        const highlightedManufacturer = result[1].highlight(matchHighlight)

        const renderedLabel = result[0].target == '' ? result.obj.label : highlightedLabel
        const renderedManufacturer = result[1].target == '' ? result.obj.manufacturer : highlightedManufacturer

        return {
            ...result.obj,
            renderedLabel,
            renderedManufacturer,
        }
    })
}

type DocumentsTableDataTypes = APIDocument | DocumentUpload

const isAPIDocument = (document: APIDocument | DocumentUpload): document is APIDocument => {
    return 'sha512_hash' in document
}

const isDocumentUpload = (document: APIDocument | DocumentUpload): document is DocumentUpload => {
    return 'file' in document
}

export interface ModelChipProps extends ChipProps {
    name: string
    manufacturer: string
}

export const ModelChip = ({ name, manufacturer, ...props }: ModelChipProps) => {
    const label = <>
        <Typography component="span" color="textPrimary">{name}</Typography>
        <Typography component="span" color="textSecondary">/{manufacturer}</Typography>
    </>
    return (
        <Chip size="small" label={label} title={`${name} / ${manufacturer}`} {...props} />
    )
}

export const DocumentsTable = ({ documents, rowCount, documentUploads, isLoading, showProgressBars, pagination, setPagination, filters, setFilters, updateDocumentEnabled, updateDocument, dataSets, setDataSets, models, setModels }: DocumentsTableProps) => {
    const paginationState = useMemo(() => {
        if (pagination === undefined) return undefined

        return {
            pageIndex: (pagination.offset && pagination.limit) ? pagination.offset / pagination.limit : 0,
            pageSize: pagination.limit || 100,
        }
    }, [pagination])

    const onPaginationChange = (pagination: MRT_Updater<MRT_PaginationState>) => {
        if (typeof pagination === 'function') {
            const newPagination = pagination(table.getState().pagination)
            setPagination?.({
                offset: newPagination.pageIndex * newPagination.pageSize,
                limit: newPagination.pageSize,
            })
        } else {
            setPagination?.({
                offset: pagination.pageIndex * pagination.pageSize,
                limit: pagination.pageSize,
            })
        }
    }

    const columnFiltersState = useMemo(() => {
        if (filters === undefined) return undefined

        const columnFilters: MRT_ColumnFiltersState = []
        Object.entries(filters).forEach(([key, value]) => {
            columnFilters.push({ id: key, value })
        })

        return columnFilters
    }, [filters])

    const onColumnFiltersChange = (filters: MRT_Updater<MRT_ColumnFiltersState>) => {
        const oldFilters = table.getState().columnFilters
        let newFilters: MRT_ColumnFiltersState

        if (typeof filters === 'function') {
            newFilters = filters(oldFilters)
        } else {
            newFilters = filters
        }

        if (_.isEqual(newFilters, oldFilters)) return

        const filtersBody: Record<string, unknown> = {}
        newFilters.forEach((filter) => {
            filtersBody[filter.id] = filter.value
        })

        setFilters?.(filtersBody)
        table.firstPage()
    }

    const { Cell: DatasetsCell, Edit: DatasetsEdit, Filter: DatasetsFilter } = useMultiValueColumnDef<DocumentsTableDataTypes, APIDataSet>({
        fieldName: 'Datasets',
        optionsData: dataSets,
        renderChip: (dataset, index) => (
            <Link className='text-link' to={`/data-sets/${dataset.id}/documents`} key={index}>
                <Chip label={dataset.name} size="small" title={dataset.name} clickable />
            </Link>
        ),
        useOptions: useDataSetOptions,
        onEdit: setDataSets,
    })

    const { Cell: ModelsCell, Edit: ModelsEdit, Filter: ModelsFilter } = useMultiValueColumnDef<DocumentsTableDataTypes, APIProductModel>({
        fieldName: 'Models',
        optionsData: models,
        renderChip: (model, index) => (
            <ModelChip name={model.name} manufacturer={model.product_manufacturer?.name || ''} key={index} />
        ),
        useOptions: useModelOptions,
        onEdit: setModels,
        SearchableSelectProps: {
            renderOption: renderModelOption,
            filterOptions: filterModelOptions,
            renderTags: (value: ModelSelectOption[], getTagProps) => {
                const props = getTagProps({ index: 0 })
                return value.map((option, index) => (
                    <ModelChip name={option.label} manufacturer={option.manufacturer || ''} {...props} key={index} />
                ))
            },
            rowHeight: 53.06,
        },
    })

    const columns = useMemo<MRT_ColumnDef<DocumentsTableDataTypes>[]>(() => [
        {
            id: "open-document",
            header: "",
            Cell: ({ row }) => {
                if (!isAPIDocument(row.original)) return null

                return <Link to={`/documents/${String(row.id)}`}>
                    <IconButton size="small" title="Open details">
                        <Info fontSize='small' />
                    </IconButton>
                </Link>
            },
            columnDefType: "display",
            size: 40,
            minSize: 40,
        },
        {
            accessorKey: 'url',
            header: '',
            Cell: ({ row }) => {
                if (!isAPIDocument(row.original)) return null

                const url = row.getValue("url")
                return <Link to={String(url)} target='_blank' style={{ pointerEvents: !url ? "none" : undefined }}>
                    <Tooltip title="Open file" aria-disabled={!url}>
                        <span>
                            <IconButton size="small" disabled={!url}>
                                <OpenInNew fontSize='small' />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Link>
            },
            columnDefType: "display",
            enableColumnFilter: false,
            size: 40,
            minSize: 40,
        },
        {
            accessorKey: 'task_status',
            header: '',
            id: 'task-status',
            Cell: ({ cell, row }) => {
                const status = cell.getValue<APIQueueTaskStatus>()
                const icon = getQueueTaskStatusIcon(status)
                return <Link to={`/documents/${row.id}/tasks`}>
                    <IconButton size="small">{icon}</IconButton>
                </Link>
            },
            size: 40,
            minSize: 40,
            columnDefType: "display",
        },
        {
            accessorKey: 'is_enabled',
            header: '',
            Cell: ({ row }) => {
                if (isDocumentUpload(row.original)) {
                    let component = null
                    let tooltip = null

                    if (row.original.status === 'error') {
                        tooltip = 'Error'
                        component = <ErrorOutline color="error" />
                    } else if (row.original.status === 'pending' || row.original.status === 'uploading') {
                        const progress = row.original.progress
                        tooltip = `Uploading ${progress}%`
                        component = <Box position="relative" display="inline-flex">
                            <CircularProgress
                                variant="determinate"
                                value={progress}
                                size={20}
                                thickness={7}
                                color="warning"
                            />
                            <CircularProgress
                                variant="determinate"
                                value={100}
                                size={20}
                                thickness={7}
                                sx={{
                                    color: (theme) => theme.palette.grey[50],
                                    position: 'absolute',
                                    left: 0,
                                    zIndex: -10,
                                }}
                            />
                        </Box>
                    }

                    return (
                        <Tooltip title={tooltip}>
                            <Box display="flex" justifyContent="center" width='100%'>
                                {component}
                            </Box>
                        </Tooltip>
                    )
                } else if (isAPIDocument(row.original)) {
                    const isEnabled = row.getValue<boolean>("is_enabled")
                    const id = row.id
                    const onChange = () => updateDocumentEnabled?.(id, !row.getValue("is_enabled"))
                    return <Switch color="success" size="small" checked={isEnabled} onChange={onChange} disabled={updateDocumentEnabled === undefined} title={isEnabled ? "Disable" : "Enable"} />
                }
            },
            size: 60,
            minSize: 60,
            filterVariant: 'checkbox',
            columnDefType: "display",
        },
        {
            accessorFn: (doc) => isAPIDocument(doc) && doc.id,
            id: 'id',
            header: 'ID',
            enableClickToCopy: (cell) => isAPIDocument(cell.row.original),
            size: 400,
        },
        {
            accessorFn: (doc) => {
                if (isAPIDocument(doc)) {
                    return doc.title
                } else if (isDocumentUpload(doc)) {
                    return doc.file.name
                }
            },
            id: 'title',
            header: 'Title',
            size: 600,
            enableColumnFilter: true,
            muiTableBodyCellProps: ({ cell }) => ({
                title: cell.getValue<string>(),
            }),
            ...(updateDocument && {
                enableEditing: (row) => isAPIDocument(row.original),
                muiEditTextFieldProps: ({ cell, row }) => ({
                    type: 'text',
                    label: 'Title',
                    required: true,
                    onBlur: (event) => {
                        const title = event.target.value
                        const id = row.id
                        if (title !== cell.getValue()) {
                            updateDocument(id, { title })
                        }
                    },
                }),
            }),

        },
        {
            accessorKey: 'original_filename',
            header: 'Original Filename',
        },
        {
            accessorKey: 'sha512_hash',
            header: 'Hash',
            enableClickToCopy: (cell) => isAPIDocument(cell.row.original),
        },
        {
            accessorFn: (doc) => isAPIDocument(doc) && (doc.data_sets ?? []),
            header: 'Datasets',
            id: 'data_set_ids',
            size: 300,
            grow: true,
            Cell: ({ cell, row, ...args }) => {
                if (isAPIDocument(row.original)) {
                    return DatasetsCell!({ cell, row, ...args })
                } else if (isDocumentUpload(row.original)) {
                    const datasetId = row.original.data_set_id
                    const dataset = dataSets.find((dataSet) => dataSet.id === datasetId)
                    if (!dataset) return null
                    return <Link className='text-link' to={`/data-sets/${dataset.id}/documents`}>
                        <Chip key={dataset.id} label={dataset.name} size="small" clickable />
                    </Link>
                }
            },
            enableEditing: (row) => isAPIDocument(row.original) && setDataSets !== undefined,
            Edit: DatasetsEdit,
            enableColumnFilter: true,
            Filter: DatasetsFilter,
            filterFn: 'objectId',
        },
        {
            accessorFn: (document) => isAPIDocument(document) && document.document_to_product_model?.map((model) => model.product_model),
            header: "Models",
            id: "model_ids",
            size: 300,
            grow: true,
            Cell: ModelsCell,
            enableEditing: (row) => isAPIDocument(row.original) && setModels !== undefined,
            Edit: ModelsEdit,
            enableColumnFilter: true,
            Filter: ModelsFilter,
            filterFn: 'objectId',
        },
        {
            accessorKey: '_count.sections',
            header: 'Sections',
            filterVariant: 'range-slider',
        },
        {
            accessorFn: (doc) => isAPIDocument(doc) && (doc.versions?.length || 0),
            header: 'Versions',
            filterVariant: 'range-slider',
        },
        {
            accessorKey: 'type',
            header: 'Type',
            enableEditing: (row) => isAPIDocument(row.original) && updateDocument !== undefined,
            Edit: ({ cell, row, table }) => {
                const currentType = cell.getValue<APIDocumentType>()
                const id = row.id
                const documentTypes: string[] = Object.values(APIDocumentType as Record<string, string>)

                const [type, setType] = useState(currentType)
                const onChange = (event: SelectChangeEvent<string>) => {
                    const type = event.target.value as APIDocumentType
                    setType(type)

                }
                const onBlur = () => {
                    updateDocument?.(id, { type: type })
                    table.setEditingCell(null)
                }

                return (
                    <Select
                        fullWidth
                        required
                        label='User'
                        size='small'
                        variant='standard'
                        defaultValue={currentType}
                        onChange={onChange}
                        onBlur={onBlur}
                    >
                        {
                            documentTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))
                        }
                    </Select>
                )
            }
        },
        {
            accessorFn: (doc) => isAPIDocument(doc) && doc.updated_at,
            header: 'Updated At',
            id: 'updated_at',
            Cell: ({ cell }) => formatDate(cell.getValue<DateTime>()),
            filterVariant: 'datetime-range',
        },
        {
            accessorFn: (doc) => isAPIDocument(doc) && doc.created_at,
            header: 'Created At',
            id: 'created_at',
            Cell: ({ cell }) => formatDate(cell.getValue<DateTime>()),
            filterVariant: 'datetime-range',
        },
    ], [DatasetsCell, DatasetsEdit, DatasetsFilter, ModelsCell, ModelsEdit, ModelsFilter, dataSets, setDataSets, setModels, updateDocument, updateDocumentEnabled]);


    const isManualPaginationFirstPage = pagination && pagination.offset == 0
    const isNotManualPagination = !pagination
    const isAppendingUploads = documentUploads && (isManualPaginationFirstPage || isNotManualPagination)

    const data: DocumentsTableDataTypes[] = useMemo(() => {
        if (isAppendingUploads) {
            return [...documentUploads, ...documents]
        }
        return documents
    }, [documentUploads, documents, isAppendingUploads])

    const rowCountTotal = useMemo(() => {
        if (isAppendingUploads && rowCount) {
            return rowCount + documentUploads.length
        }
        return rowCount
    }, [documentUploads, isAppendingUploads, rowCount])

    const table = useAdminMaterialReactTable({
        columns,
        data,
        rowCount: rowCountTotal,
        initialState: {
            columnVisibility: {
                id: false,
                auth0_sub: false,
                sha512_hash: false,
                original_filename: false,
                created_at: false,
                type: false,
            },
            columnPinning: {
                left: ['mrt-row-select', 'open-document', 'url', 'task-status', 'is_enabled'],
            }
        },
        defaultColumn: {
            minSize: 80,
            size: 200,
            enableSorting: true,
        },
        enableSorting: pagination === undefined,
        enableColumnResizing: true,
        layoutMode: "grid-no-grow",
        enableRowSelection: (row) => isAPIDocument(row.original),
        getRowId: (doc) => doc.id,
        ...(setPagination && { manualPagination: true, onPaginationChange }),
        ...(setFilters && { manualFiltering: true, onColumnFiltersChange }),
        state: {
            isLoading,
            showProgressBars,
            ...(pagination && paginationState && { pagination: paginationState }),
            ...(filters && columnFiltersState && { columnFilters: columnFiltersState }),
        },
    }, 'documents-table');

    return <MaterialReactTable table={table} />
}

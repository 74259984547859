import type { APITestRun } from '@visorpro/client'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import type { Stores } from '../../util/store'
import { Checkbox } from '../checkbox/checkbox'
import { Table } from '../table/table'
import { TableHeader } from '../table/table-header'
import { TestRunsBulkSelectModal } from './test-run-bulk-select-modal'
import { TestRunRow } from './test-run-row'
import { TestRunsTableStore } from './test-runs-table-store'

export interface TestQuestionsTableProps {
    stores: Stores
    testRuns: APITestRun[]
    showDocumentsColumn?: boolean
}

export const TestRunsTable: FC<TestQuestionsTableProps> = observer(
    ({ stores, testRuns, showDocumentsColumn = false }) => {
        const now = DateTime.now()

        const [tableStore] = useState(() => new TestRunsTableStore())

        const headerCheckboxValue = useMemo(() => {
            return testRuns.every(
                (testRun) => tableStore.selectedTestRunIds[testRun.id],
            )
        }, [testRuns, tableStore.selectedTestRunIds])

        const onHeaderChange = useCallback(() => {
            if (headerCheckboxValue) {
                tableStore.clearSelection()
            } else {
                tableStore.selectAll(testRuns)
            }
        }, [headerCheckboxValue, tableStore, testRuns])

        const selectedTestRunIds = Object.keys(tableStore.selectedTestRunIds)

        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>
                                <Checkbox
                                    value={headerCheckboxValue}
                                    onChange={onHeaderChange}
                                />
                            </TableHeader>
                            <TableHeader>Score</TableHeader>
                            <TableHeader>Date</TableHeader>
                            <TableHeader>Prompt</TableHeader>
                            <TableHeader>Model</TableHeader>
                            <TableHeader>Res tokens</TableHeader>
                            <TableHeader>Matches</TableHeader>
                            {showDocumentsColumn && (
                                <TableHeader>Document</TableHeader>
                            )}
                            <TableHeader>Questions</TableHeader>
                            <TableHeader>Assertions</TableHeader>
                            <TableHeader>Duration</TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {testRuns.map((testRun) => {
                            return (
                                <TestRunRow
                                    key={testRun.id}
                                    testRunId={testRun.id}
                                    now={now}
                                    tableStore={tableStore}
                                    stores={stores}
                                    onShowMenu={() => console.log('show menu')}
                                    showDocumentsColumn={showDocumentsColumn}
                                />
                            )
                        })}
                    </tbody>
                </Table>

                {selectedTestRunIds.length > 0 && (
                    <TestRunsBulkSelectModal
                        stores={stores}
                        testRunIds={selectedTestRunIds}
                        isCopyingTestRuns={
                            stores.testRuns.copyTestRuns.isRunning
                        }
                        onClearPress={tableStore.clearSelection}
                    />
                )}
            </>
        )
    },
)

import { observer } from 'mobx-react-lite'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { TestRunsTable } from '../../components/test-runs-table/test-runs-table'
import { TextButton } from '../../components/text-button'
import { useTestRuns } from '../../stores/test-runs'
import type { Stores } from '../../util/store'

export interface DocumentTestRunsProps {
    stores: Stores
}

export const TestRunsPage = observer(({ stores }: DocumentTestRunsProps) => {
    const testRuns = useTestRuns(stores)

    if (!document) {
        return null
    }

    let textButton: JSX.Element | null = null
    const pendingTestRuns = stores.testRuns.getPendingTestRuns().length

    if (stores.testRuns.processTestRuns.isRunning) {
        textButton = <TextButton kind="active">Running...</TextButton>
    } else if (pendingTestRuns > 0) {
        textButton = (
            <TextButton
                kind="primary"
                onClick={() => stores.testRuns.processTestRuns.run()}>
                {`Process ${pendingTestRuns}`}
            </TextButton>
        )
    }

    return (
        <div style={{ padding: '1rem' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                <BreadCrumbs name="Test runs" path={[]} />
                {textButton}
            </div>

            <TestRunsTable
                showDocumentsColumn
                stores={stores}
                testRuns={testRuns}
            />
        </div>
    )
})

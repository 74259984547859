import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { TestQuestionsTable } from '../../../../components/test-questions-table/test-questions-table'
import { useDocumentById } from '../../../../stores/documents'
import { useDocumentTestQuestions } from '../../../../stores/test-questions'
import type { Stores } from '../../../../util/store'

export interface DocumentQuestionsProps {
    stores: Stores
}

export const DocumentQuestions = observer(
    ({ stores }: DocumentQuestionsProps) => {
        const { documentId } = useParams()
        const document = useDocumentById(stores, documentId!)

        const testQuestions = useDocumentTestQuestions(stores, documentId!)

        if (!document) {
            return null
        }

        return (
            <div>
                <TestQuestionsTable
                    stores={stores}
                    testQuestions={testQuestions}
                />
            </div>
        )
    },
)

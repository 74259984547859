import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { TestRunsTable } from '../../../../components/test-runs-table/test-runs-table'
import { useDocumentById } from '../../../../stores/documents'
import { useDocumentTestRuns } from '../../../../stores/test-runs'
import type { Stores } from '../../../../util/store'

export interface DocumentTestRunsProps {
    stores: Stores
}

export const DocumentTestRuns = observer(
    ({ stores }: DocumentTestRunsProps) => {
        const { documentId } = useParams()
        const document = useDocumentById(stores, documentId!)

        const testRuns = useDocumentTestRuns(stores, documentId!)

        if (!document) {
            return null
        }

        return (
            <div>
                <TestRunsTable stores={stores} testRuns={testRuns} />
            </div>
        )
    },
)

import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import type { Stores } from '../../util/store'
import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { DocumentSectionPreview } from './section-preview'
import { DocumentsTable } from '../../components/documents-table';
import { useDocuments, type APIUpdateDocumentRequest } from '../../stores/documents'
import { useDataSets } from '../../stores/data-sets-store'
import { useModels } from '../../stores/models-store'
import { useDocumentUploads } from '../../stores/document-upload'

export interface ModelsPageProps {
    stores: Stores
}

export const DocumentsPage = observer(({ stores }: ModelsPageProps) => {
    const [pagination, setPagination] = useState({})
    const [filters, setFilters] = useState({})

    const options = useMemo(() => ({
        ...pagination,
        ...filters,
    }), [pagination, filters])

    const documents = useDocuments(stores, options, true)
    const documentUploads = useDocumentUploads(stores)
    const dataSets = useDataSets(stores)
    const models = useModels(stores)

    const [documentSectionPreviewRequest, setDocumentSectionPreviewRequest] =
        useState<APIGetRandomSectionsRequest>()

    const setEnabled = useCallback((documentId: string, isEnabled: boolean) => {
        void stores.documents.setDocumentEnabled.run(documentId, isEnabled)
    }, [stores.documents.setDocumentEnabled])

    const updateDocument = useCallback((documentId: string, body: APIUpdateDocumentRequest) => {
        void stores.documents.updateDocument.run(documentId, body)
    }, [stores.documents.updateDocument])

    const setDataSets = useCallback(async (documentId: string, addedDatasetIds: string[], removedDataSetIds: string[]) => {
        await Promise.all([
            stores.documents.addDocumentToDataSets.run(documentId, addedDatasetIds),
            stores.documents.removeDocumentFromDataSets.run(documentId, removedDataSetIds),
        ])
    }, [stores.documents])

    const setModels = useCallback(async (documentId: string, addedModelIds: string[], removedModelIds: string[]) => {
        await Promise.all([
            stores.documents.addDocumentToModels.run(documentId, addedModelIds),
            stores.documents.removeDocumentFromModels.run(documentId, removedModelIds),
        ])
    }, [stores.documents])

    return (
        <>
            <DocumentsTable
                rowCount={stores.documents.totalDocuments}
                documents={documents}
                dataSets={dataSets}
                models={models}
                documentUploads={documentUploads}
                isLoading={stores.documents.isFetching}
                showProgressBars={stores.documents.isUpdating}
                pagination={pagination}
                setPagination={setPagination}
                filters={filters}
                setFilters={setFilters}
                updateDocumentEnabled={setEnabled}
                updateDocument={updateDocument}
                setDataSets={setDataSets}
                setModels={setModels}
            />

            {documentSectionPreviewRequest && (
                <DocumentSectionPreview
                    request={documentSectionPreviewRequest}
                    stores={stores}
                    onClose={() => {
                        setDocumentSectionPreviewRequest(undefined)
                    }}
                />
            )}
        </>
    )
})

import type { APICreateCategoryRequest } from '@visorpro/client/dist/RestClient/services/CategoryService'
import type { APIProductCategory } from '@visorpro/client'
import { makeObservable, observable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { visorPRORestClient } from '../util/api'
import { ObservableTask } from '../util/observable-task'
import type { Stores } from '../util/store'

export class CategoriesStore {
    public didFetchCategories = false
    public categoriesById: Record<string, APIProductCategory | undefined> = {}
    public categoryIds: string[] = []

    constructor() {
        makeObservable(this, {
            categoriesById: observable,
            categoryIds: observable,
        })
    }

    public getCategories = new ObservableTask(async () => {
        try {
            const response = await visorPRORestClient.category.get()

            const categories: Record<string, APIProductCategory> = {}
            const categoryIds: string[] = []

            response.items.forEach((category) => {
                categories[category.id] = category
                categoryIds.push(category.id)
            })

            runInAction(() => {
                this.categoriesById = categories
                this.categoryIds = categoryIds
                this.didFetchCategories = true
            })
        } catch (e) {
            toast.error(`Failed to fetch categories: ${(e as Error).message}`)
        }
    })

    public create = new ObservableTask<string | undefined>(
        async (input: APICreateCategoryRequest) => {
            try {
                const category = await visorPRORestClient.category.create(input)

                runInAction(() => {
                    this.categoriesById[category.id] = category
                    this.categoryIds = [category.id, ...this.categoryIds]
                })

                return category.id
            } catch (e) {
                toast.error(
                    `Failed to create category: ${(e as Error).message}`,
                )
            }
        },
    )

    public addCategories(categories: APIProductCategory[]) {
        const copy = {
            ...this.categoriesById,
        }

        categories.forEach((category) => {
            copy[category.id] = category
        })

        this.categoriesById = copy
    }
}

export const useCategories = (stores: Stores) => {
    useEffect(() => {
        if (!stores.categories.didFetchCategories) {
            stores.categories.getCategories.run()
        }
    }, [stores.categories])

    return useMemo(() => {
        const categories: APIProductCategory[] = []

        stores.categories.categoryIds.forEach((id) => {
            const category = stores.categories.categoriesById[id]
            if (category) {
                categories.push(category)
            }
        })

        categories.sort((a, b) => a.name.localeCompare(b.name))

        return categories
    }, [stores.categories.categoryIds, stores.categories.categoriesById])
}


export const useCategoryById = (id: string | undefined, stores: Stores) => {
    useEffect(() => {
        if (!stores.categories.didFetchCategories) {
            stores.categories.getCategories.run()
        }
    }, [stores.categories])

    if (id) {
        return stores.categories.categoriesById[id]
    }
}

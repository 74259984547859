import { RawTypeConverter } from '@visorpro/client'
import { makeObservable, observable, runInAction } from 'mobx'
import { useEffect, useMemo } from 'react'
import type { APIDataSet, APIDataSetRaw } from '../types'
import { rawDataSetToDataSet } from '../types'
import type { APIOrganizationDataSetRaw } from '../types/data-set-organization'
import { apiClient, visorPRORestClient } from '../util/api'
import { ObservableKeyedTask } from '../util/observable-keyed-task'
import { ObservableTask } from '../util/observable-task'
import type { Stores } from '../util/store'

interface APIGetDataSetResponse {
    total: number
    items: APIDataSetRaw[]
}

export interface APICreateDataSetRequest {
    name: string
    organization_id?: string
}

export interface APICreateDataSetResponse {
    created: boolean
    data_set: APIDataSetRaw
}

export interface APIUpdateDataSetRequest {
    name: string
}

interface APICreateOrganizationDataSetResponse {
    created: boolean
    organization_data_set: APIOrganizationDataSetRaw
}

export class DataSetStore {
    public dataSetsById: Record<string, APIDataSet> = {}
    public dataSetIds: string[] = []
    public dataSetIdsByOrganizationId: Record<string, string[] | undefined> = {}

    constructor(private readonly stores: Stores) {
        makeObservable(this, {
            dataSetsById: observable,
            dataSetIds: observable,
        })
    }

    public updateDataSet = new ObservableTask(
        async (dataSetId: string, options: APIUpdateDataSetRequest) => {
            const response = await apiClient.put<APIDataSetRaw>(
                `/data-set/${dataSetId}`,
                options,
            )

            runInAction(() => {
                this.dataSetsById = {
                    ...this.dataSetsById,
                    [response.data.id]: rawDataSetToDataSet(response.data),
                }
            })
        },
    )

    public addDataSets(dataSets: APIDataSet[]) {
        const dataSetsById: Record<string, APIDataSet> = {}

        dataSets.forEach((dataSet) => {
            dataSetsById[dataSet.id] = dataSet
        })

        this.dataSetsById = {
            ...this.dataSetsById,
            ...dataSetsById,
        }
    }

    public getDataSets = new ObservableTask(async () => {
        const rawDataSets =
            await apiClient.get<APIGetDataSetResponse>('/data-set')

        const dataSetsById: Record<string, APIDataSet> = {}
        const dataSetIds: string[] = []

        rawDataSets.data.items.forEach((rawDataSet) => {
            const dataSet = rawDataSetToDataSet(rawDataSet)
            dataSetIds.push(rawDataSet.id)
            dataSetsById[rawDataSet.id] = dataSet
        })

        runInAction(() => {
            this.dataSetsById = dataSetsById
            this.dataSetIds = dataSetIds
        })
    })

    public create = new ObservableTask(
        async (input: APICreateDataSetRequest) => {
            const dataSets = await apiClient.post<APICreateDataSetResponse>(
                '/data-set',
                input,
            )

            runInAction(() => {
                if (dataSets.data.created) {
                    this.dataSetsById[dataSets.data.data_set.id] =
                        rawDataSetToDataSet(dataSets.data.data_set)
                    this.dataSetIds = [
                        dataSets.data.data_set.id,
                        ...this.dataSetIds,
                    ]

                    if (input.organization_id) {
                        this.stores.organizationDataSets.addDataSetIdToOrganization(
                            input.organization_id,
                            dataSets.data.data_set.id,
                        )
                    }
                }
            })
        },
    )

    public addOrganizationDataSet = new ObservableKeyedTask(
        async (dataSetId: string, organizationId: string) => {
            const response =
                await visorPRORestClient.callAPI<APICreateOrganizationDataSetResponse>(
                    {
                        method: 'POST',
                        urlPath: `data-set/${dataSetId}/organization/${organizationId}`,
                        authenticate: true,
                    },
                )

            if (response.created) {
                const organizationDataSet = RawTypeConverter.fixTimestamps(
                    response.organization_data_set,
                )

                runInAction(() => {
                    this.dataSetsById[dataSetId].organizations = [
                        ...this.dataSetsById[organizationDataSet.data_set_id]
                            .organizations,
                        RawTypeConverter.fixTimestamps(
                            organizationDataSet.organization,
                        ),
                    ]
                    this.stores.organizationDataSets.addDataSetIdToOrganization(
                        organizationDataSet.organization.id,
                        organizationDataSet.data_set_id,
                    )
                })
            }
        },
    )

    public removeOrganizationDataSet = new ObservableKeyedTask(
        async (dataSetId: string, organizationId: string) => {
            await visorPRORestClient.callAPI<APICreateOrganizationDataSetResponse>(
                {
                    method: 'DELETE',
                    urlPath: `data-set/${dataSetId}/organization/${organizationId}`,
                    authenticate: true,
                },
            )

            runInAction(() => {
                this.dataSetsById[dataSetId].organizations = this.dataSetsById[
                    dataSetId
                ].organizations.filter((org) => org.id !== organizationId)
                this.stores.organizationDataSets.removeDataSetIdFromOrganization(
                    organizationId,
                    dataSetId,
                )
            })
        },
    )

    public addOrRemoveOrganizationDataSet(
        dataSetId: string,
        organizationId: string,
    ) {
        const dataSet = this.dataSetsById[dataSetId]

        const hasOrganization = dataSet.organizations.some(
            (org) => org.id === organizationId,
        )

        if (hasOrganization) {
            this.removeOrganizationDataSet.run(dataSetId, organizationId)
        } else {
            this.addOrganizationDataSet.run(dataSetId, organizationId)
        }
    }
}

export const useDataSets = (stores: Stores) => {
    useEffect(() => {
        if (stores.dataSets.dataSetIds.length === 0) {
            void stores.dataSets.getDataSets.run()
        }
    }, [stores.dataSets.dataSetIds, stores.dataSets.getDataSets])

    return useMemo(() => {
        return Object.values(stores.dataSets.dataSetsById)
    }, [stores.dataSets.dataSetsById])
}

export const useDataSetById = (
    stores: Stores,
    id: string,
): APIDataSet | undefined => {
    useEffect(() => {
        if (!stores.dataSets.dataSetsById[id]) {
            stores.dataSets.getDataSets.run()
        }
    }, [stores.dataSets.dataSetsById, stores.dataSets.getDataSets, id])

    return useMemo(() => {
        return stores.dataSets.dataSetsById[id]
    }, [stores.dataSets.dataSetsById, id])
}

export const useDataSetsById = (
    stores: Stores,
    ids: Set<string>,
): APIDataSet[] => {
    useEffect(() => {
        if (!Object.keys(stores.dataSets.dataSetsById)) {
            stores.dataSets.getDataSets.run()
        }
    }, [stores.dataSets.dataSetsById, stores.dataSets.getDataSets, ids])

    return useMemo(() => {
        return Array.from(ids).map((id) => stores.dataSets.dataSetsById[id])
    }, [stores.dataSets.dataSetsById, ids])
}

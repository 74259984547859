import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef } from 'react'
import { Outlet, useParams, useMatches } from 'react-router-dom'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { TextButton } from '../../components/text-button'
import './index.css'
import { NavBar } from './nav-bar'
import { RouteName } from '../../util/router'
import { useModal } from '../../components/modal'
import type { Stores } from '../../util/store'
import {
    MembersSearchModal,
    SelectableListModal,
} from '../../components/modals'
import { useOrganizationMembers } from '../../stores/organization-members-store'
import type { APIDataSet } from '../../types'
import { useOrganizationDataSets } from '../../stores/organization-data-sets-store'
import { useDataSets } from '../../stores/data-sets-store'
import { logBackendError } from '../../util/api'
import { SingleTextFieldModal } from '../../components/modals/updates/single-text-field-modal'
import { v4 } from 'uuid'
import { Stack } from '@mui/material'

export interface OrganizationDetailsPageProps {
    stores: Stores
}

const createCodeString = () => {
    const DEFAULT_CODE_CHAR = 16
    return v4()
        .replaceAll('-', '')
        .substring(0, DEFAULT_CODE_CHAR)
        .toUpperCase()
}

export const OrganizationDetailsPage = observer(
    ({ stores }: OrganizationDetailsPageProps) => {
        const { organizationId } = useParams()
        const organization =
            stores.organizations.organizationsById[organizationId!]
        const matches = useMatches()
        const lastMatch = matches[matches.length - 1]
        const anchorRef = useRef<HTMLButtonElement>(null)

        useEffect(() => {
            if (!organization) {
                stores.organizations.fetchOrganizations()
            }
        }, [organization, stores])

        const manageDataSetsModal = useModal()
        const addOrganizationMemberModal = useModal()

        const createDataSet = useCallback(
            async (value: string) => {
                try {
                    await stores.dataSets.create.run({
                        name: value,
                        organization_id: organizationId!,
                    })
                } catch (e) {
                    logBackendError(
                        e,
                        `There was an error while creating '${value}'`,
                    )
                }
            },
            [stores, organizationId],
        )

        const onSelect = useCallback(
            (userId: string) => {
                const user = stores.users.usersById[userId]

                if (!user) return

                if (
                    user.memberships?.some(
                        (m) => m.organization?.id === organizationId,
                    )
                ) {
                    stores.organizations.removeOrganizationMember(
                        organizationId!,
                        userId,
                    )
                } else {
                    stores.organizations.addOrganizationMember(
                        organizationId!,
                        userId,
                    )
                }
            },
            [stores, organizationId],
        )
        const members = useOrganizationMembers(
            stores.organizationMembers,
            organizationId!,
        )

        const createInvitationCodeModal = useModal<string>()
        const createInvitationCode = useCallback(
            async (_identifier: string, code?: string) => {
                await stores.organizationCodes.createCode.run(
                    organizationId!,
                    code,
                )
                createInvitationCodeModal.close()
            },
            [stores, organizationId, createInvitationCodeModal],
        )

        const createDomainModal = useModal<string>()
        const createDomain = useCallback(
            async (_identifier: string, domain: string) => {
                const didSucceed =
                    await stores.organizationDomains.createDomain.run(
                        organizationId!,
                        domain,
                    )
                if (didSucceed) createDomainModal.close()
            },
            [stores, organizationId, createDomainModal],
        )

        const allDataSets = useDataSets(stores)
        const dataSetsInOrganization = useOrganizationDataSets(
            stores,
            organizationId!,
        )

        const addOrRemoveOrganizationDataSet = useCallback(
            (dataSet: APIDataSet) => {
                stores.dataSets.addOrRemoveOrganizationDataSet(
                    dataSet.id,
                    organization.id,
                )
            },
            [stores, organization],
        )

        if (!organization) return null

        return (
            <Stack className="organization-details" style={{ overflow: 'none', height: '100vh' }}>
                <BreadCrumbs
                    name={organization.name}
                    path={[
                        {
                            name: 'Organizations',
                            to: '/organizations',
                        },
                    ]}
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        marginTop: '1rem',
                    }}>
                    <NavBar />

                    {lastMatch.id === RouteName.OrganizationDetailsDataSets && (
                        <TextButton
                            ref={anchorRef}
                            onClick={manageDataSetsModal.open}>
                            Manage data sets
                        </TextButton>
                    )}

                    {lastMatch.id ===
                        RouteName.OrganizationDetailsInvitationCodes && (
                            <TextButton
                                ref={anchorRef}
                                onClick={(e) =>
                                    createInvitationCodeModal.open(
                                        e,
                                        createCodeString(),
                                    )
                                }>
                                Create code
                            </TextButton>
                        )}

                    {lastMatch.id === RouteName.OrganizationDetailsDomains && (
                        <TextButton
                            ref={anchorRef}
                            onClick={(e) => createDomainModal.open(e)}>
                            Create domain
                        </TextButton>
                    )}
                </div>

                <Outlet />

                {manageDataSetsModal.state && (
                    <SelectableListModal
                        selectableListProps={{
                            selectedIds: new Set(
                                dataSetsInOrganization.map((ds) => ds.id),
                            ),
                            onSelect: addOrRemoveOrganizationDataSet,
                            isLoading: stores.dataSets.getDataSets.isRunning,
                            items: allDataSets,
                            placeholder: 'Search data sets...',
                            onSubmit: createDataSet,
                        }}
                        anchor={manageDataSetsModal.state.anchor}
                        onClose={manageDataSetsModal.close}
                    />
                )}

                {addOrganizationMemberModal.state && (
                    <MembersSearchModal
                        anchor={addOrganizationMemberModal.state.anchor}
                        onSelect={onSelect}
                        onClose={addOrganizationMemberModal.close}
                        stores={stores}
                        activeUserIds={
                            new Set(members.map((member) => member.id))
                        }
                    />
                )}

                {createInvitationCodeModal.state && (
                    <SingleTextFieldModal
                        identifier={''}
                        initialName={createInvitationCodeModal.state.data ?? ''}
                        anchor={createInvitationCodeModal.state.anchor}
                        onClose={createInvitationCodeModal.close}
                        onSubmit={createInvitationCode}
                        onSubmitWhileEmpty={createInvitationCode}
                        isLoading={
                            stores.organizationCodes.createCode.isRunning
                        }
                        contentStyle={{ marginTop: 0, width: '500px' }}
                        placeholder="Enter a name for the code..."
                        placement="bottom-end"
                        submitText="Create"
                        submitTextLoading="Creating..."
                    />
                )}

                {createDomainModal.state && (
                    <SingleTextFieldModal
                        identifier={''}
                        initialName={createDomainModal.state.data ?? ''}
                        anchor={createDomainModal.state.anchor}
                        onClose={createDomainModal.close}
                        onSubmit={createDomain}
                        isLoading={
                            stores.organizationDomains.createDomain.isRunning
                        }
                        contentStyle={{ marginTop: 0, width: '500px' }}
                        placeholder="Enter a domain..."
                        placement="bottom-end"
                        submitText="Create"
                        submitTextLoading="Creating..."
                    />
                )}
            </Stack>
        )
    },
)

import { DateTime } from 'luxon'
import type { FC } from 'react'
import { RuleSetEditor } from '../../components/rule-set-editor'
import './navbar.css'

export interface ParserEditorNavbarProps {
    documentId?: string
    parserId?: string
}

export const ParserEditorNavbar: FC<ParserEditorNavbarProps> = () => {
    return (
        <nav className="parser-editor-navbar">
            <div>
                <span className="small-text color-dimmed">Document:</span>

                <p className="section-title">
                    A Series Operator_s Manual (model year 2013), 169000
                    Revision E
                </p>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '1rem',
                    }}>
                    <span className="small-text color-orange">Parsing</span>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <span className="small-text color-purple">
                            Re-parse
                        </span>
                        <span
                            className="small-text color-purple"
                            style={{ marginLeft: '0.5rem' }}>
                            Re-embed
                        </span>
                    </div>
                </div>
            </div>

            <hr />

            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <span className="small-text color-dimmed">Parser:</span>
                    <span className="small-text color-green">
                        Set as current
                    </span>
                </div>

                <p className="section-title">Bourgault docs - Times</p>

                <div style={{ marginTop: '1rem' }}>
                    <span className="small-text color-dimmed">Rule sets:</span>

                    <RuleSetEditor
                        ruleSet={{
                            id: '1',
                            created_at: DateTime.local(),
                            updated_at: DateTime.local(),
                            from_page: 17,
                            to_page: 320,
                            parser_id: 'parser_id_1',
                            rules: [
                                {
                                    id: '1',
                                    created_at: DateTime.local(),
                                    updated_at: DateTime.local(),
                                    hierarchy: 'h1',
                                    casing: null,
                                    casing_negated: false,
                                    color: null,
                                    color_negated: false,
                                    font: null,
                                    font_negated: false,
                                    rule_set_id: '1',
                                    kind: 'text',
                                    size_between: null,
                                    size_between_negated: false,
                                    size_exact: 18.6,
                                    size_exact_negated: false,
                                    within_images: null,
                                    within_rect_negated: false,
                                    within_rect: null,
                                },
                                {
                                    id: '2',
                                    created_at: DateTime.local(),
                                    updated_at: DateTime.local(),
                                    hierarchy: 'h2',
                                    casing: null,
                                    casing_negated: false,
                                    color: null,
                                    color_negated: false,
                                    font: null,
                                    font_negated: false,
                                    rule_set_id: '1',
                                    kind: 'text',
                                    size_between: null,
                                    size_between_negated: false,
                                    size_exact: null,
                                    size_exact_negated: false,
                                    within_images: null,
                                    within_rect_negated: false,
                                    within_rect: null,
                                },
                                {
                                    id: '3',
                                    created_at: DateTime.local(),
                                    updated_at: DateTime.local(),
                                    hierarchy: 'h3',
                                    casing: null,
                                    casing_negated: false,
                                    color: null,
                                    color_negated: false,
                                    font: null,
                                    font_negated: false,
                                    rule_set_id: '1',
                                    kind: 'text',
                                    size_between: null,
                                    size_between_negated: false,
                                    size_exact: null,
                                    size_exact_negated: false,
                                    within_images: null,
                                    within_rect_negated: false,
                                    within_rect: null,
                                },
                            ],
                        }}
                        style={{ marginTop: '0.5rem' }}
                    />
                </div>
            </div>
        </nav>
    )
}

import { memo } from 'react'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import type { usePdfDocument } from '../util/pdf'

export interface ParserEditorThumbnailsProps {
    onPageClick(page: number): void
    doc: ReturnType<typeof usePdfDocument>
}

export const PdfThumbnails: FC<ParserEditorThumbnailsProps> = memo(
    ({ onPageClick, doc }) => {
        const containerRef = useRef<HTMLDivElement>(null)

        useEffect(() => {
            const element = containerRef.current
            const didCancel = {
                current: false,
            }

            if (!doc.pdfDoc || !element) return

            const renderPages = async (from: number, to: number) => {
                for (let pageNum = from; pageNum <= to; pageNum++) {
                    await doc.renderPage({
                        element,
                        pageNumber: pageNum,
                        onPageClick,
                        pageWidth: 150,
                        didCancel,
                    })
                }
            }

            const renderBatches = async (batchSize: number) => {
                for (let i = 1; i <= doc.numPages; i += batchSize) {
                    await renderPages(
                        i,
                        Math.min(i + batchSize - 1, doc.numPages),
                    )
                }
            }

            renderBatches(30)

            return () => {
                element.innerHTML = ''
                didCancel.current = true
            }
        }, [doc, onPageClick])

        return (
            <div
                ref={containerRef}
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                }}
            />
        )
    },
)

import type { MRT_ColumnOrderState, MRT_ColumnSizingState, MRT_SortingState, MRT_VisibilityState, MRT_RowData, MRT_TableOptions, MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { useMaterialReactTable } from 'material-react-table'
import { ContentCopy } from '@mui/icons-material';
import { type Updater, type FilterFn } from '@tanstack/table-core';
import { useEffectOnce, useKey, useLocalStorage } from 'react-use';
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash'

import { SearchableSelect, type SearchableSelectProps, type SearchableSelectOption } from '../searchable-select';

declare module '@tanstack/table-core' {
    interface FilterFns {
        objectId: FilterFn<MRT_RowData>
    }
}

export const useAdminMaterialReactTable = <TData extends MRT_RowData>(options: MRT_TableOptions<TData>, tableName: string) => {
    const [columnVisibility, setColumnVisibility] = useLocalStorage<MRT_VisibilityState>(`${tableName}-column-visibility`, options.initialState?.columnVisibility ?? {});
    const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(`${tableName}-column-sizing`, options.initialState?.columnSizing ?? {});
    const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>(`${tableName}-column-order`, options.initialState?.columnOrder ?? []);
    const [columnSorting, setColumnSorting] = useLocalStorage<MRT_SortingState>(`${tableName}-column-sorting`, options.initialState?.sorting ?? []);
    useKey(
        (event) => (event.ctrlKey || event.metaKey) && event.key === 'f',
        (event) => {
            if (!table.getState().showColumnFilters) {
                event.preventDefault();
                table.setShowColumnFilters(true);
            }
        },
        { event: 'keydown' }
    );

    const mergedOptions: MRT_TableOptions<MRT_RowData> = useMemo(() => {
        const { initialState, defaultColumn, defaultDisplayColumn, state, ...rest } = options
        const enableRowSelection = options.enableRowSelection ?? true
        const enableRowActions = options.enableRowActions ?? false
        const columnPinning = {
            ...(enableRowSelection && { left: ['mrt-row-select'] }),
            ...(enableRowActions && { right: ['mrt-row-actions'] }),
        }

        return {
            initialState: {
                pagination: {
                    pageSize: 1000,
                    pageIndex: 0,
                },
                density: 'compact',
                columnPinning,
                ...initialState,
            },
            defaultColumn: {
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: false,
                enablePinning: false,
                ...defaultColumn,
            },
            defaultDisplayColumn: {
                visibleInShowHideMenu: false,
                enableClickToCopy: false,
                enableColumnActions: false,
                enableColumnDragging: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enablePinning: false,
                enableEditing: false,
                enableGlobalFilter: false,
                enableGrouping: false,
                enableHiding: false,
                enableResizing: false,
                enableSorting: false,
                size: 40,
                ...defaultDisplayColumn,
            },
            displayColumnDefOptions: {
                'mrt-row-actions': {
                    size: 80,
                },
                'mrt-row-select': {
                    size: 40,
                    minSize: 40,
                    maxSize: 40,
                },
            },
            enableEditing: true,
            enableFilters: true,
            enableFilterMatchHighlighting: true,
            enableGlobalFilter: false,
            enableSorting: false,
            enableRowSelection: true,
            enableStickyHeader: true,
            enableStickyFooter: true,
            enableColumnOrdering: true,
            enableColumnPinning: true,
            enableDensityToggle: false,
            enableFacetedValues: false,
            enableColumnResizing: false,
            enableFullScreenToggle: false,
            memoMode: 'cells',
            editDisplayMode: 'cell',
            paginationDisplayMode: 'pages',
            positionToolbarAlertBanner: 'bottom',
            muiEditTextFieldProps: {
                type: 'text',
                size: 'small',
                inputProps: { style: { fontSize: '0.875rem' } },
            },
            muiCopyButtonProps: {
                startIcon: <ContentCopy />,
                sx: { justifyContent: 'flex-start' },
            },
            muiPaginationProps: {
                rowsPerPageOptions: [100, 500, 1000, 2000, 5000, 10000],
                boundaryCount: 1,
                siblingCount: 3,
            },
            enableRowVirtualization: true,
            autoResetPageIndex: false,
            muiTablePaperProps: { sx: { height: '100%' } },
            muiTableContainerProps: ({ table }) => ({ sx: { height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)` } }),
            columnResizeMode: 'onEnd',
            filterFns: {
                objectId: (row: MRT_Row<TData>, columnId: string, filterValues: string[]) => {
                    const getId = (object: unknown) => {
                        if (object && typeof object === 'object' && 'id' in object) {
                            return String(object.id)
                        }
                        return String(object)
                    }

                    const columnValue = row.getValue(columnId)
                    if (Array.isArray(columnValue)) {
                        const ids = columnValue.map(getId)
                        return _.intersection(ids, filterValues).length > 0
                    }
                    return filterValues.includes(getId(columnValue))
                },
            },
            state: {
                ...state,
                ...(columnVisibility && { columnVisibility }),
                ...(columnSizing && { columnSizing }),
                ...(columnOrder && { columnOrder }),
                ...(columnSorting && { sorting: columnSorting }),
            },
            onColumnVisibilityChange: (updaterOrValue) => applyUpdaterOrValue(updaterOrValue, columnVisibility ?? {}, setColumnVisibility),
            onColumnSizingChange: (updaterOrValue) => applyUpdaterOrValue(updaterOrValue, columnSizing ?? {}, setColumnSizing),
            onColumnOrderChange: (updaterOrValue) => applyUpdaterOrValue(updaterOrValue, columnOrder ?? [], setColumnOrder),
            onSortingChange: (updaterOrValue) => applyUpdaterOrValue(updaterOrValue, columnSorting ?? [], setColumnSorting),
            ...rest,
        } as MRT_TableOptions<MRT_RowData>
        // eslint-disable-next-line
    }, [options])

    const table = useMaterialReactTable(mergedOptions)
    return table
}

const applyUpdaterOrValue = <T extends object>(updaterOrValue: Updater<T>, state: T, setState: Dispatch<SetStateAction<T | undefined>>) => {
    const newState = updaterOrValue instanceof Function ? updaterOrValue(state) : updaterOrValue
    setState(newState)
}

export interface UseMultiValueColumnProps<_RowData, ColumnData> {
    fieldName: string
    optionsData?: ColumnData[]
    renderChip?: (entry: ColumnData, index: number) => JSX.Element
    useOptions?: (data: ColumnData[]) => SearchableSelectOption[]
    onEdit?: (id: string, additions: string[], deletions: string[]) => void
    SearchableSelectProps?: Partial<SearchableSelectProps<SearchableSelectOption>>
}

export const useMultiValueColumnDef = <RowData extends MRT_RowData, ColumnData>({ fieldName, optionsData, renderChip, useOptions, onEdit, SearchableSelectProps }: UseMultiValueColumnProps<RowData, ColumnData>) => {
    const options = useOptions?.(optionsData ?? [])

    const Cell: MRT_ColumnDef<RowData>['Cell'] = ({ cell }) => {
        const entries = Array.from(cell.getValue<ColumnData[]>())
        return (
            <Box display="flex" alignItems="left" gap={2} flexWrap="wrap">
                {entries.map((entry, index) => renderChip?.(entry, index))}
            </Box>
        )
    }

    const Edit: MRT_ColumnDef<RowData>['Edit'] = ({ cell, table }) => {
        const id = cell.row.getValue<string>("id")
        const originalValues = Array.from(cell.getValue<ColumnData[]>())
        const originalOptions = useOptions!(originalValues)
        const [selectedOptions, setSelectedOptions] = useState(originalOptions)

        const onBlur = () => {
            const selectedIds = selectedOptions.map((option) => option.value)
            const originalIds = originalOptions.map((option) => option.value)
            const additions = _.difference(selectedIds, originalIds)
            const deletions = _.difference(originalIds, selectedIds)
            if (additions.length > 0 || deletions.length > 0) {
                onEdit?.(id, additions, deletions)
            }
            table.setEditingCell(null)
        }

        return (
            <SearchableSelect
                multiple
                value={selectedOptions}
                onChange={(_, value) => setSelectedOptions(value)}
                options={options!}
                onBlur={onBlur}
                TextFieldProps={{ label: fieldName, required: true, placeholder: selectedOptions.length === 0 ? `Select ${fieldName}` : '' }}
                {...SearchableSelectProps}
            />
        )
    }

    const extractFilterValueIds = (values: SearchableSelectOption[]) => values.length > 0 ? values.map(v => v.value) : undefined
    const Filter: MRT_ColumnDef<RowData>['Filter'] = ({ header, table }) => {
        const [values, setValues] = useState<SearchableSelectOption[]>([])
        useEffectOnce(() => {
            table.getState().columnFilters.forEach((filter) => {
                if (filter.id === header.column.id && useOptions) {
                    // eslint-disable-next-line
                    setValues(useOptions(filter.value as ColumnData[]))
                }
            })
        })

        return (
            <SearchableSelect
                multiple
                defaultValue={values}
                options={options!}
                TextFieldProps={{ placeholder: `Filter by ${fieldName}` }}
                onChange={(_, values) => header.column.setFilterValue(extractFilterValueIds(values))}
                {...SearchableSelectProps}
            />
        )
    }

    const returnedOptions: Partial<MRT_ColumnDef<RowData>> = {}

    if (renderChip) {
        returnedOptions.Cell = Cell
    }

    if (optionsData && useOptions) {
        returnedOptions.enableColumnFilter = true
        returnedOptions.Filter = Filter
    } else {
        returnedOptions.enableColumnFilter = false
    }

    if (onEdit) {
        returnedOptions.Edit = Edit
        returnedOptions.enableEditing = true
    } else {
        returnedOptions.enableEditing = false
    }

    return returnedOptions
}
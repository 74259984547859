import type { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { MdMoreVert } from 'react-icons/md'
import type { APIDataSet } from '../../types'
import type { Stores } from '../../util/store'
import { Checkbox } from '../checkbox/checkbox'
import type { TextListItem } from '../table-text-list'
import { TableTextList } from '../table-text-list'
import { TableDateCell } from '../table/table-date-cell'
import { TableLinkCell } from '../table/table-link-cell'
import { TableRow } from '../table/table-row'
import { TableTextCell } from '../table/table-text-cell'
import type { DataSetTableStore } from './data-set-table-store'

export interface DataSetRowProps {
    tableStore: DataSetTableStore
    stores: Stores
    dataSetId: string
    createLink(dataSet: APIDataSet): string
    now: DateTime
    onShowMenu(e: React.MouseEvent, dataSet: APIDataSet): void
}

export const DataSetRow: FC<DataSetRowProps> = observer(
    ({ tableStore, stores, dataSetId, createLink, now, onShowMenu }) => {
        const dataSet = stores.dataSets.dataSetsById[dataSetId]
        const organizationNames = useMemo<TextListItem[]>(() => {
            if (!dataSet.organizations) return []

            return dataSet.organizations
                .map((org) => ({
                    id: org.id,
                    name: org.name,
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        }, [dataSet.organizations])

        const onMoreClick = useCallback(
            (e: React.MouseEvent) => {
                onShowMenu(e, dataSet)
            },
            [onShowMenu, dataSet],
        )

        const _onSelectionChange = useCallback(
            (value: boolean) => {
                tableStore.setDataSetSelection(dataSetId, value)
            },
            [tableStore, dataSetId],
        )

        return (
            <TableRow key={dataSet.id}>
                <td className="table-cell">
                    <Checkbox
                        value={tableStore.selectedDataSetIds[dataSetId]}
                        onChange={_onSelectionChange}
                    />
                </td>

                <TableLinkCell to={createLink(dataSet)}>
                    {dataSet.name}
                </TableLinkCell>

                <TableTextList
                    visibleItems={1000}
                    items={organizationNames}
                    createLink={({ id }) => `/organizations/${id}/data-sets`}
                />

                <TableTextCell>{dataSet.documents_count}</TableTextCell>

                <TableDateCell date={dataSet.created_at} now={now} />

                <td className="table-cell">
                    <MdMoreVert
                        size={18}
                        onClick={onMoreClick}
                        className="dimmed-pressable-icon"
                    />
                </td>
            </TableRow>
        )
    },
)

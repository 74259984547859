import type {
    APICreateTestQuestionRequest,
    APIUpdateAcceptanceCriteriaInput,
} from '@visorpro/client/dist/RestClient/services/TestQuestionService'
import { makeObservable, observable, runInAction } from 'mobx'
import { v4 } from 'uuid'
import { ListStore } from '../../util/list-store'
import type { Stores } from '../../util/store'

export interface AcceptanceCriteriaInput {
    //`id` is only set for items that already exist in the api
    id?: string
    // `key` is used in react list components.
    // defaults to `id` for existing items, and a new uuid for new ones.
    key: string
    text: string
}

export class QuestionFormStore {
    public text = ''
    public acceptanceCriteriaStore: ListStore<AcceptanceCriteriaInput>

    constructor(initialText: string, initialItems: AcceptanceCriteriaInput[]) {
        this.text = initialText
        this.acceptanceCriteriaStore = new ListStore({
            initialItems,
            createItem: () => ({
                key: v4(),
                text: '',
            }),
        })

        makeObservable(this, {
            text: observable,
        })
    }

    public setText = (text: string) => {
        runInAction(() => {
            this.text = text
        })
    }

    public setAcceptanceCriteriaText = (key: string, text: string) => {
        this.acceptanceCriteriaStore.updateItem(key, (item) => ({
            ...item,
            text,
        }))
    }

    public create = async (
        stores: Stores,
        options: Partial<APICreateTestQuestionRequest>,
    ) => {
        return stores.testQuestions.createTestQuestion.run({
            ...options,
            text: this.text.trim(),
            acceptance_criteria: this.acceptanceCriteriaStore
                .getItems()
                .map((item) => ({
                    text: item.text,
                })),
        })
    }

    public update = async (stores: Stores, testQuestionId: string) => {
        return stores.testQuestions.updateTestQuestion.run(testQuestionId, {
            text: this.text.trim(),
            acceptance_criteria: this.acceptanceCriteriaStore
                .getItems()
                .map<APIUpdateAcceptanceCriteriaInput>((item) => ({
                    id: item.id,
                    text: item.text,
                })),
        })
    }
}

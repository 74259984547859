import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { PageTitle } from '../../components/page-title'
import { Table } from '../../components/table/table'
import { TableHeader } from '../../components/table/table-header'
import { TableLinkCell } from '../../components/table/table-link-cell'
import { TableRow } from '../../components/table/table-row'
import { TableTextCell } from '../../components/table/table-text-cell'
import type { ParserStore } from '../../stores/parser-store'
import './index.css'

export interface ParserListPageProps {
    store: ParserStore
}

export const ParserListPage = observer(({ store }: ParserListPageProps) => {
    useEffect(() => {
        if (!store.parserIds.length) {
            store.fetchParsers()
        }
    }, [store])

    return (
        <div className="parser-list">
            <PageTitle>Parsers</PageTitle>

            <Table>
                <thead>
                    <tr>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Documents</TableHeader>
                    </tr>
                </thead>

                <tbody>
                    {store.parserIds.map((parserId) => {
                        const parser = store.parsersById[parserId]
                        return (
                            <TableRow key={parser.id}>
                                <TableLinkCell to={`/parsers/${parser.id}`}>
                                    {parser.name}
                                </TableLinkCell>
                                <TableTextCell>
                                    {parser.documents.length}
                                </TableTextCell>
                            </TableRow>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
})

import type { APIOrganization } from '@visorpro/client'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useOrganizations } from '../../stores/organization-store'
import type { Stores } from '../../util/store'
import { SelectableList } from '../modals'
import { Modal } from '../modal'

interface DataSetBulkOrganizationsModalProps {
    stores: Stores
    dataSetIds: string[]
    onClose(): void
}

export const DataSetBulkOrganizationsModal: FC<DataSetBulkOrganizationsModalProps> =
    observer(({ stores, dataSetIds, onClose }) => {
        const dataSets = dataSetIds.map(
            (id) => stores.dataSets.dataSetsById[id],
        )

        // only organizations that are common to all data sets should be active
        const activeOrganizationIds = useMemo<Set<string>>(() => {
            if (dataSets.length === 0) return new Set()

            if (dataSets.some((dataSet) => !dataSet.organizations.length))
                return new Set()

            let commonOrganizationIds = new Set(
                dataSets[0].organizations.map((org) => org.id),
            )

            dataSets.forEach((dataSet) => {
                commonOrganizationIds = new Set(
                    dataSet.organizations
                        .filter((org) => commonOrganizationIds.has(org.id))
                        .map((org) => org.id),
                )
            })

            return commonOrganizationIds
        }, [dataSets])

        const onSelect = useCallback(
            (org: APIOrganization) => {
                dataSetIds.forEach((dataSetId) => {
                    const dataSet = stores.dataSets.dataSetsById[dataSetId]
                    const organizationExists = dataSet.organizations.some(
                        (o) => o.id === org.id,
                    )

                    if (activeOrganizationIds.has(org.id)) {
                        if (!organizationExists) return
                        stores.dataSets.removeOrganizationDataSet.run(
                            dataSetId,
                            org.id,
                        )
                    } else {
                        if (organizationExists) return
                        stores.dataSets.addOrganizationDataSet.run(
                            dataSetId,
                            org.id,
                        )
                    }
                })
            },
            [stores, dataSetIds, activeOrganizationIds],
        )

        const organizations = useOrganizations(stores.organizations)

        return (
            <Modal onClose={onClose}>
                <SelectableList
                    selectedIds={activeOrganizationIds}
                    onSelect={onSelect}
                    isLoading={stores.organizations.isFetchingOrganizations}
                    items={organizations}
                    placeholder="Search organizations..."
                />
            </Modal>
        )
    })

import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback } from 'react'
import type { Stores } from '../../util/store'
import { Modal } from '../modal'
import { FormSeparator } from '../modals/creation/form-separator'
import { FormTextInput } from '../modals/creation/form-text-input'
import { TextButton } from '../text-button'
import type { TestRunConfigFormStore } from './test-run-config-form-store'
import { TestRunConfigModalStep } from './test-run-config-modal-step'

export interface TestRunConfigModalMainProps {
    testRunIds?: string[]
    stores: Stores
    onClose(): void
    anchor: HTMLElement
    formStore: TestRunConfigFormStore
    onStepChange(step: TestRunConfigModalStep): void
}

export const TestRunConfigModalMain: FC<TestRunConfigModalMainProps> = ({
    stores,
    onClose,
    anchor,
    formStore,
    onStepChange,
}) => {
    const submit = useCallback(
        (e: React.FormEvent) => {
            e.preventDefault()
            formStore.submit(stores)
            onClose()
        },
        [formStore, stores, onClose],
    )

    return (
        <Modal anchor={anchor} onClose={onClose}>
            <form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 0.5rem 0.5rem',
                }}
                onSubmit={submit}>
                <span className="color-dimmed" style={{ marginTop: '0.5rem' }}>
                    Prompt
                </span>
                <PromptLabel
                    stores={stores}
                    formStore={formStore}
                    style={{ marginTop: '0.5rem' }}
                    onStepChange={onStepChange}
                />

                <span className="color-dimmed" style={{ marginTop: '1rem' }}>
                    Model
                </span>
                <ModelLabel
                    stores={stores}
                    formStore={formStore}
                    style={{ marginTop: '0.5rem' }}
                    onStepChange={onStepChange}
                />

                <span className="color-dimmed" style={{ marginTop: '1rem' }}>
                    Response tokens limit
                </span>
                <ResponseTokensLimitInput
                    stores={stores}
                    formStore={formStore}
                    style={{ marginTop: '0.5rem' }}
                />

                <span className="color-dimmed" style={{ marginTop: '1rem' }}>
                    Total tokens limit
                </span>

                <span className="color-dimmed" style={{ marginTop: '0.5rem' }}>
                    8000
                </span>

                <FormSeparator />

                <span className="color-dimmed" style={{ marginTop: '0.5rem' }}>
                    Embedding matches
                </span>
                <EmbeddingMatchesInput
                    stores={stores}
                    formStore={formStore}
                    style={{ marginTop: '0.5rem' }}
                />

                <FormSeparator />

                <TextButton
                    onClick={submit}
                    disabled={stores.testRuns.createTestRun.isRunning}
                    style={{ color: 'var(--green-color)' }}>
                    Create
                </TextButton>
            </form>
        </Modal>
    )
}

interface InputProps {
    stores: Stores
    formStore: TestRunConfigFormStore
    style?: React.CSSProperties
    onStepChange?(step: TestRunConfigModalStep): void
}

const PromptLabel: FC<InputProps> = observer(
    ({ stores, formStore, style, onStepChange }) => {
        const onClick = useCallback(() => {
            onStepChange?.(TestRunConfigModalStep.Prompt)
        }, [onStepChange])

        let name = 'None'
        let color = 'var(--comment-color)'

        if (formStore.promptId) {
            const prompt = stores.prompts.promptsById[formStore.promptId]

            if (prompt) {
                name = prompt.name
                color = 'var(--foreground-color)'
            }
        }

        return (
            <span
                className="color-dimmed"
                style={{
                    ...style,
                    cursor: 'pointer',
                    color,
                }}
                onClick={onClick}>
                {name}
            </span>
        )
    },
)

const ModelLabel: FC<InputProps> = observer(
    ({ formStore, style, onStepChange }) => {
        const onClick = useCallback(() => {
            onStepChange?.(TestRunConfigModalStep.Model)
        }, [onStepChange])

        let name = 'None'
        let color = 'var(--comment-color)'

        if (formStore.model) {
            name = formStore.model
            color = 'var(--foreground-color)'
        }

        return (
            <span
                className="color-dimmed"
                style={{
                    ...style,
                    cursor: 'pointer',
                    color,
                }}
                onClick={onClick}>
                {name}
            </span>
        )
    },
)

const ResponseTokensLimitInput: FC<InputProps> = observer(
    ({ formStore, style }) => {
        return (
            <FormTextInput
                value={formStore.responseTokensLimit?.toString() ?? ''}
                onChange={formStore.setResponseTokensLimit}
                placeholder=""
                style={style}
            />
        )
    },
)

const EmbeddingMatchesInput: FC<InputProps> = observer(
    ({ formStore, style }) => {
        return (
            <FormTextInput
                value={formStore.embeddingMatches?.toString() ?? ''}
                onChange={formStore.setEmbeddingMatches}
                placeholder=""
                style={style}
            />
        )
    },
)

import type { APIDocumentRaw } from '@visorpro/client'
import { RawTypeConverter } from '@visorpro/client'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import pMap from 'p-map'
import { v4 } from 'uuid'
import { apiClient } from '../util/api'
import type { Stores } from '../util/store'
import { useMemo } from 'react'

export interface DocumentUpload {
    id: string
    file: File
    data_set_id: string
    progress: number
    status: 'pending' | 'uploading' | 'error'
}

const MAX_CONCURRENT_UPLOADS = 2

export class DocumentUploadStore {
    public documentUploadsById: Record<string, DocumentUpload> = {}

    constructor(private readonly stores: Stores) {
        makeObservable(this, {
            documentUploadsById: observable,
            enqueueDocumentUploads: action,
        })
    }

    public getDataSetUploads(dataSetId: string) {
        return Object.values(this.documentUploadsById).filter(
            (upload) => upload.data_set_id === dataSetId,
        )
    }

    public getDocumentUploads() {
        return computed(() => Object.values(this.documentUploadsById)).get()
    }

    public async enqueueDocumentUploads(dataSetId: string, files: FileList) {
        const uploads = Array.from(files).map((file) => {
            const upload: DocumentUpload = {
                id: v4(),
                file,
                data_set_id: dataSetId,
                progress: 0,
                status: 'pending',
            }
            return upload
        })

        runInAction(() => {
            uploads.forEach((upload) => {
                this.documentUploadsById[upload.id] = upload
            })
        })

        await pMap(uploads, (upload) => this._runUpload(upload), {
            concurrency: MAX_CONCURRENT_UPLOADS,
        })
    }

    private async _runUpload(upload: DocumentUpload) {
        const formData = new FormData()
        formData.append('data_set_ids[]', upload.data_set_id)
        formData.append('file', upload.file)

        runInAction(() => {
            this.documentUploadsById[upload.id] = {
                ...upload,
                status: 'uploading',
            }
        })

        try {
            const response = await apiClient.post<APIDocumentRaw>(
                `/document`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (event) => {
                        runInAction(() => {
                            const progress = Math.round(
                                (event.progress ?? 0) * 100,
                            )
                            this.documentUploadsById[upload.id] = {
                                ...upload,
                                progress,
                            }
                        })
                    },
                },
            )

            runInAction(() => {
                delete this.documentUploadsById[upload.id]

                const document = RawTypeConverter.fixDocument(response.data)

                if (upload.data_set_id) {
                    const dataSetId = upload.data_set_id
                    const dataSets =
                        this.stores.dataSetDocuments.documentsByIdByDatasets
                    const oldDataSet = dataSets[dataSetId]
                    const newDataSet = {
                        [document.id]: document,
                        ...oldDataSet,
                    }
                    dataSets[dataSetId] = newDataSet
                }
            })
        } catch (e) {
            runInAction(() => {
                this.documentUploadsById[upload.id] = {
                    ...upload,
                    status: 'error',
                }
            })
        }
    }
}

export const useDocumentUploads = (stores: Stores) => {
    return useMemo(() => {
        return computed(() => {
            return Object.values(stores.documentUploads.documentUploadsById)
        }).get()
    }, [stores.documentUploads.documentUploadsById])
}

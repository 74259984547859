import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DeleteButton } from '../../../../components/delete-button'
import { Table } from '../../../../components/table/table'
import { TableDateCell } from '../../../../components/table/table-date-cell'
import { TableHeader } from '../../../../components/table/table-header'
import { TableRow } from '../../../../components/table/table-row'
import { TableTextCell } from '../../../../components/table/table-text-cell'
import { useCodesByOrganizationId } from '../../../../stores/organization-invitation-codes-store'
import type { Stores } from '../../../../util/store'

export interface OrganizationInvitationCodesPageProps {
    stores: Stores
}

export const OrganizationInvitationCodesPage = observer(
    ({ stores }: OrganizationInvitationCodesPageProps) => {
        const { organizationId } = useParams()
        const codes = useCodesByOrganizationId(stores, organizationId!)

        useEffect(() => {
            if (!codes) {
                stores.organizationCodes.fetchOrganizationCodes.run(
                    organizationId!,
                )
            }
        }, [
            codes,
            stores.organizationCodes.fetchOrganizationCodes,
            organizationId,
        ])

        const deleteCode = useCallback(
            (codeId: string) => {
                stores.organizationCodes.deleteOrganizationCode.run(
                    organizationId!,
                    codeId,
                )
            },
            [stores.organizationCodes.deleteOrganizationCode, organizationId],
        )

        const now = DateTime.now()

        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Code</TableHeader>
                            <TableHeader>Created</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {codes?.map(({ id, code, created_at }) => {
                            return (
                                <TableRow key={id}>
                                    <TableTextCell>{code}</TableTextCell>

                                    <TableDateCell
                                        date={created_at}
                                        now={now}
                                    />

                                    <td className="table-cell">
                                        <DeleteButton
                                            onDelete={() => deleteCode(id)}
                                        />
                                    </td>
                                </TableRow>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    },
)

import type { APIOrganization } from '@visorpro/client'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useOrganizations } from '../../stores/organization-store'
import type { Stores } from '../../util/store'
import { SelectableListModal } from '../modals/search/selectable-list-modal'

interface DataSetOrganizationsModalProps {
    stores: Stores
    dataSetId: string
    anchor: HTMLElement
    onClose(): void
}

export const DataSetOrganizationsModal: FC<DataSetOrganizationsModalProps> =
    observer(({ stores, dataSetId, anchor, onClose }) => {
        const dataSet = stores.dataSets.dataSetsById[dataSetId]

        const activeOrganizationIds = useMemo(() => {
            const result = new Set<string>()

            dataSet.organizations?.forEach((organization) => {
                result.add(organization.id)
            })

            return result
        }, [dataSet.organizations])

        const onSelect = useCallback(
            (org: APIOrganization) => {
                stores.dataSets.addOrRemoveOrganizationDataSet(
                    dataSet.id,
                    org.id,
                )
            },
            [stores, dataSet],
        )

        const organizations = useOrganizations(stores.organizations)

        return (
            <SelectableListModal
                selectableListProps={{
                    selectedIds: activeOrganizationIds,
                    onSelect: onSelect,
                    isLoading: stores.organizations.isFetchingOrganizations,
                    items: organizations,
                    placeholder: 'Search organizations...',
                }}
                anchor={anchor}
                onClose={onClose}
            />
        )
    })

import { observer } from "mobx-react-lite"
import { useInterval } from "react-use"
import { useCallback } from "react"
import type { PaginatedRequestQueryParams } from "@visorpro/client"
import type { APIGetQueueTaskRequestFilteringParams } from "@visorpro/client/dist/PipelineClient/services"

import { QueueTasksTable } from "../../../../components/tasks-table"
import { useQueueTasks } from "../../../../stores/queue-tasks-store"
import { type Stores } from "../../../../util/store"
import { useParams } from "react-router-dom"


const INTERVAL_DELAY = 5000


export interface QueueTasksPageProps {
  stores: Stores
}

export const DocumentQueueTasks = observer(({ stores }: QueueTasksPageProps) => {
  const { documentId } = useParams()

  if (!documentId) {
    return null
  }

  const tasks = useQueueTasks(stores, { document_id: documentId })

  useInterval(() => {
    void stores.queueTasks.fetchTasks.run(stores.queueTasks.queryParams)
  }, INTERVAL_DELAY)

  const setPagination = useCallback((pagination: PaginatedRequestQueryParams) => {
    void stores.queueTasks.fetchTasks.run({
      ...stores.queueTasks.queryParams,
      ...pagination,
    })
  }, [stores.queueTasks.fetchTasks, stores.queueTasks.queryParams])

  const setFilters = useCallback((filters: APIGetQueueTaskRequestFilteringParams) => {
    void stores.queueTasks.fetchTasks.run({
      offset: stores.queueTasks.queryParams.offset,
      limit: stores.queueTasks.queryParams.limit,
      ...filters,
    })
  }, [stores.queueTasks.fetchTasks, stores.queueTasks.queryParams.limit, stores.queueTasks.queryParams.offset])

  return (
    <QueueTasksTable
      tasks={tasks}
      rowCount={stores.queueTasks.totalTasks}
      isLoading={stores.queueTasks.isFetching && !stores.queueTasks.isFetching}
      isSaving={stores.queueTasks.isUpdating}
      pagination={stores.queueTasks.queryParams}
      setPagination={setPagination}
      filters={stores.queueTasks.queryParams}
      setFilters={setFilters}
    />
  )
})

export default DocumentQueueTasks

import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { BreadCrumbs } from '../../components/breadcrumbs'
import { IssueTable } from '../../components/issues-table/issues-table'
import { useIssues } from '../../stores/issues'
import type { Stores } from '../../util/store'

export interface IssuesPageProps {
    stores: Stores
}

export const IssuesPage: FC<IssuesPageProps> = observer(({ stores }) => {
    const issues = useIssues(stores)

    return (
        <div style={{ padding: '1rem' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                <BreadCrumbs name="Issues" path={[]} />
            </div>

            <IssueTable items={issues} stores={stores} />
        </div>
    )
})

import type { APIGetRandomSectionsRequest } from '@visorpro/client/dist/RestClient/services'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DataSetsTable } from '../../../../components/data-sets-table'
import { useOrganizationDataSets } from '../../../../stores/organization-data-sets-store'
import type { APIDataSet } from '../../../../types'
import type { Stores } from '../../../../util/store'
import { DocumentSectionPreview } from '../../../documents/section-preview'

export interface OrganizationDataSetsPageProps {
    stores: Stores
}

export const OrganizationDataSetsPage = observer(
    ({ stores }: OrganizationDataSetsPageProps) => {
        const { organizationId } = useParams()
        const dataSets = useOrganizationDataSets(stores, organizationId!)

        const createLink = useCallback((dataSet: APIDataSet) => {
            return `/data-sets/${dataSet.id}/documents`
        }, [])

        const [
            documentSectionPreviewRequest,
            setDocumentSectionPreviewRequest,
        ] = useState<APIGetRandomSectionsRequest>()

        if (!dataSets) return null

        return (
            <div>
                <DataSetsTable
                    stores={stores}
                    dataSets={dataSets}
                    createLink={createLink}
                    onReviewStart={setDocumentSectionPreviewRequest}
                />

                {documentSectionPreviewRequest && (
                    <DocumentSectionPreview
                        request={documentSectionPreviewRequest}
                        stores={stores}
                        onClose={() => {
                            setDocumentSectionPreviewRequest(undefined)
                        }}
                    />
                )}
            </div>
        )
    },
)

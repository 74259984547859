import type { Placement } from '@floating-ui/react'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import React from 'react'
import type { APIDataSet } from '../../../types'
import { SingleTextFieldModal } from './single-text-field-modal'

export interface DataSetNameModalProps {
    dataSet: APIDataSet
    anchor: HTMLElement
    onClose?(): void
    onSubmit?(dataSetId: string, name: string): void
    isLoading: boolean
    contentStyle?: React.CSSProperties
    placement: Placement
}

export const DataSetNameModal: FC<DataSetNameModalProps> = observer(
    ({
        dataSet,
        anchor,
        onClose,
        onSubmit,
        isLoading,
        contentStyle,
        placement,
    }) => {
        return (
            <SingleTextFieldModal
                identifier={dataSet.id}
                initialName={dataSet.name}
                anchor={anchor}
                onClose={onClose}
                onSubmit={onSubmit}
                isLoading={isLoading}
                contentStyle={contentStyle}
                placeholder="Case IH"
                placement={placement}
            />
        )
    },
)

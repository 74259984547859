import { CategoriesStore } from '../stores/categories-store'
import { DataSetDocumentsStore } from '../stores/data-set-documents-store'
import { DataSetStore } from '../stores/data-sets-store'
import { DocumentUploadStore } from '../stores/document-upload'
import { DocumentsStore } from '../stores/documents'
import { IssuesStore } from '../stores/issues'
import { ManufacturerStore } from '../stores/manufacturers-store'
import { ModelsStore } from '../stores/models-store'
import { OrganizationDataSetsStore } from '../stores/organization-data-sets-store'
import { OrganizationDomainsStore } from '../stores/organization-domains-store'
import { OrganizationInvitationCodesStore } from '../stores/organization-invitation-codes-store'
import { OrganizationMemberStore } from '../stores/organization-members-store'
import { OrganizationStore } from '../stores/organization-store'
import { ParserStore } from '../stores/parser-store'
import { PromptsStore } from '../stores/prompts'
import { QueueTasksStore } from '../stores/queue-tasks-store'
import { SectionPreviewStore } from '../stores/section-preview'
import { TestQuestionsStore } from '../stores/test-questions'
import { TestRunsStore } from '../stores/test-runs'
import { UserStore } from '../stores/user-store'

export interface Stores {
    // remember to create an instance below when adding a new store
    documentUploads: DocumentUploadStore
    documents: DocumentsStore
    organizations: OrganizationStore
    organizationMembers: OrganizationMemberStore // fixme: plural
    organizationDataSets: OrganizationDataSetsStore
    parser: ParserStore
    users: UserStore
    organizationCodes: OrganizationInvitationCodesStore
    organizationDomains: OrganizationDomainsStore
    dataSets: DataSetStore
    dataSetDocuments: DataSetDocumentsStore
    manufacturers: ManufacturerStore
    categories: CategoriesStore
    models: ModelsStore
    issues: IssuesStore
    sectionPreview: SectionPreviewStore
    testQuestions: TestQuestionsStore
    testRuns: TestRunsStore
    prompts: PromptsStore
    queueTasks: QueueTasksStore
}

export const createStores = (): Stores => {
    const stores = {
        parser: new ParserStore(),
        users: new UserStore(),
        organizationMembers: new OrganizationMemberStore(),
        organizationCodes: new OrganizationInvitationCodesStore(),
        categories: new CategoriesStore(),
        sectionPreview: new SectionPreviewStore(),
        organizationDomains: new OrganizationDomainsStore(),
        testQuestions: new TestQuestionsStore(),
        testRuns: new TestRunsStore(),
        prompts: new PromptsStore(),
    } as Stores

    stores.documents = new DocumentsStore(stores)
    stores.issues = new IssuesStore(stores)
    stores.manufacturers = new ManufacturerStore(stores)
    stores.models = new ModelsStore(stores)
    stores.dataSets = new DataSetStore(stores)
    stores.dataSetDocuments = new DataSetDocumentsStore(stores)
    stores.organizationDataSets = new OrganizationDataSetsStore(stores)
    stores.organizations = new OrganizationStore(stores)
    stores.documentUploads = new DocumentUploadStore(stores)
    stores.queueTasks = new QueueTasksStore()

    return stores
}

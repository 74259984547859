import type {
    APIOrganization,
    APIOrganizationRaw,
    APIOrganizationMembershipRaw,
    APIMedia,
} from '@visorpro/client'
import { RawTypeConverter } from '@visorpro/client'
import { action, makeObservable, observable, runInAction } from 'mobx'

import { apiClient, visorPRORestClient } from '../util/api'
import type { Stores } from '../util/store'
import { useEffect, useMemo } from 'react'
import { ObservableKeyedTask } from '../util/observable-keyed-task'

export interface APICreateOrganizationRequest {
    name: string
}

interface APIGetOrganizationsResponse {
    total: number
    items: APIOrganizationRaw[]
}

interface APICreateOrganizationMemberResponse {
    created: boolean
    membership: APIOrganizationMembershipRaw
}

export class OrganizationStore {
    public organizationsById: Record<string, APIOrganization> = {}
    public organizationIds: string[] = []

    constructor(private readonly stores: Stores) {
        makeObservable(this, {
            organizationsById: observable,
            organizationIds: observable,
            fetchOrganizations: action,
            isFetchingOrganizations: observable,
            isCreatingOrganization: observable,
            membersBeingAddedByOrganizationId: observable,
            membersBeingRemovedByOrganizationId: observable,
        })
    }

    public isFetchingOrganizations = false

    public async fetchOrganizations() {
        if (this.isFetchingOrganizations) return

        this.isFetchingOrganizations = true

        try {
            const response =
                await visorPRORestClient.callAPI<APIGetOrganizationsResponse>({
                    method: 'GET',
                    urlPath: 'organization',
                    authenticate: true,
                })

            const organizationsById: Record<string, APIOrganization> = {}
            const organizationIds: string[] = []

            const organizations = response.items.map((rawOrganization) =>
                RawTypeConverter.fixTimestamps(rawOrganization),
            )
            organizations.forEach((organization) => {
                organizationsById[organization.id] = organization
                organizationIds.push(organization.id)
            })

            runInAction(() => {
                this.organizationsById = organizationsById
                this.organizationIds = organizationIds
            })
        } finally {
            runInAction(() => {
                this.isFetchingOrganizations = false
            })
        }
    }

    public isCreatingOrganization = false

    public async createOrganization(input: APICreateOrganizationRequest) {
        if (this.isCreatingOrganization) return

        runInAction(() => {
            this.isCreatingOrganization = true
        })

        try {
            const response =
                await visorPRORestClient.callAPI<APIOrganizationRaw>({
                    method: 'POST',
                    urlPath: 'organization',
                    json: input,
                    authenticate: true,
                })

            const organization = RawTypeConverter.fixTimestamps(response)

            runInAction(() => {
                this.organizationsById[organization.id] = organization
                this.organizationIds = [
                    organization.id,
                    ...this.organizationIds,
                ]
            })
        } finally {
            runInAction(() => {
                this.isCreatingOrganization = false
            })
        }
    }

    public uploadLogo = new ObservableKeyedTask(
        async (organizationId: string, file: File) => {
            const formData = new FormData()
            formData.append('file', file)

            const url = `/organization/${organizationId}/logo`
            const response = await apiClient.post<APIMedia>(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })

            runInAction(() => {
                if (this.organizationsById[organizationId]) {
                    this.organizationsById = {
                        ...this.organizationsById,
                        [organizationId]: {
                            ...this.organizationsById[organizationId],
                            logo: response.data,
                        },
                    }
                }
            })
        },
        (organizationId) => organizationId,
    )

    public membersBeingAddedByOrganizationId: Record<string, Set<string>> = {}

    public async addOrganizationMember(organizationId: string, userId: string) {
        if (this.membersBeingAddedByOrganizationId[organizationId]?.has(userId))
            return

        if (!this.membersBeingAddedByOrganizationId[organizationId]) {
            this.membersBeingAddedByOrganizationId[organizationId] = new Set()
        }

        this.membersBeingAddedByOrganizationId[organizationId].add(userId)

        try {
            await visorPRORestClient.callAPI<APICreateOrganizationMemberResponse>(
                {
                    method: 'POST',
                    urlPath: `organization/${organizationId}/member/${userId}`,
                    authenticate: true,
                },
            )
        } finally {
            runInAction(() => {
                const membersBeingAdded =
                    this.membersBeingAddedByOrganizationId[organizationId]

                if (membersBeingAdded) {
                    membersBeingAdded.delete(userId)
                }
            })
        }
    }

    public membersBeingRemovedByOrganizationId: Record<string, Set<string>> = {}

    public async removeOrganizationMember(
        organizationId: string,
        userId: string,
    ) {
        if (
            this.membersBeingRemovedByOrganizationId[organizationId]?.has(
                userId,
            )
        )
            return

        if (!this.membersBeingRemovedByOrganizationId[organizationId]) {
            this.membersBeingRemovedByOrganizationId[organizationId] = new Set()
        }

        this.membersBeingRemovedByOrganizationId[organizationId].add(userId)

        try {
            await visorPRORestClient.callAPI<APIOrganizationMembershipRaw>({
                method: 'DELETE',
                urlPath: `organization/${organizationId}/member/${userId}`,
                authenticate: true,
            })
        } finally {
            runInAction(() => {
                const membersBeingRemoved =
                    this.membersBeingRemovedByOrganizationId[organizationId]

                if (membersBeingRemoved) {
                    membersBeingRemoved.delete(userId)
                }
            })
        }
    }
}

export const useOrganizations = (store: OrganizationStore) => {
    useEffect(() => {
        if (!store.organizationIds.length) {
            store.fetchOrganizations()
        }
    }, [store])

    return useMemo(() => {
        return store.organizationIds.map((id) => store.organizationsById[id])
    }, [store.organizationIds, store.organizationsById])
}

export const useOrganization = (stores: Stores, organizationId: string) => {
    useEffect(() => {
        if (!stores.organizations.organizationsById[organizationId]) {
            stores.organizations.fetchOrganizations()
        }
    }, [stores, organizationId])

    return stores.organizations.organizationsById[organizationId]
}

import type { CSSProperties, FC } from 'react'
import type { APIRuleSet } from '../../types'
import { TextRuleEditor } from './text-rule-editor'

export interface RuleSetEditorProps {
    ruleSet: APIRuleSet
    style?: CSSProperties
}

export const RuleSetEditor: FC<RuleSetEditorProps> = ({ ruleSet, style }) => {
    return (
        <div style={style}>
            <span className="small-text color-dimmed">
                Page <span className="color-green">{ruleSet.from_page}</span> to{' '}
                <span className="color-green">{ruleSet.to_page}</span>
            </span>

            <div>
                {ruleSet.rules.map((rule) => {
                    return (
                        <TextRuleEditor
                            key={rule.id}
                            rule={rule}
                            style={{ marginTop: '0.5rem' }}
                        />
                    )
                })}
            </div>
        </div>
    )
}

import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { TestQuestionsTable } from '../../../../components/test-questions-table/test-questions-table'
import { useDataSetById } from '../../../../stores/data-sets-store'
import { useDataSetTestQuestions } from '../../../../stores/test-questions'
import type { Stores } from '../../../../util/store'

export interface DataSetQuestionsProps {
    stores: Stores
}

export const DataSetQuestions = observer(
    ({ stores }: DataSetQuestionsProps) => {
        const { dataSetId } = useParams()
        const dataSet = useDataSetById(stores, dataSetId!)

        const testQuestions = useDataSetTestQuestions(stores, dataSetId!)

        if (!dataSet) {
            return null
        }

        return (
            <div>
                <TestQuestionsTable
                    stores={stores}
                    testQuestions={testQuestions}
                />
            </div>
        )
    },
)

import { makeObservable, observable, runInAction } from 'mobx'
import { visorPRORestClient } from '../util/api'
import { ObservableTask } from '../util/observable-task'
import type { Stores } from '../util/store'
import type { APIOrganizationInviteCode } from '@visorpro/client'

export class OrganizationInvitationCodesStore {
    constructor() {
        makeObservable(this, {
            codesByOrganizationId: observable,
        })
    }

    public codesByOrganizationId: Record<
        string,
        APIOrganizationInviteCode[] | undefined
    > = {}

    public fetchOrganizationCodes = new ObservableTask(
        async (organizationId: string) => {
            const codes =
                await visorPRORestClient.organization.getInvitationCodes(
                    organizationId,
                )

            runInAction(() => {
                this.codesByOrganizationId = {
                    ...this.codesByOrganizationId,
                    [organizationId]: codes,
                }
            })
        },
    )

    public deleteOrganizationCode = new ObservableTask(
        async (organizationId: string, codeId: string) => {
            await visorPRORestClient.organization.deleteInvitationCode(
                organizationId,
                codeId,
            )

            runInAction(() => {
                this.codesByOrganizationId = {
                    ...this.codesByOrganizationId,
                    [organizationId]: this.codesByOrganizationId[
                        organizationId
                    ]?.filter((code) => code.id !== codeId),
                }
            })
        },
    )

    public createCode = new ObservableTask(
        async (organizationId: string, codeStr?: string) => {
            const code = await visorPRORestClient.organization.invitationCode(
                organizationId,
                codeStr,
            )

            runInAction(() => {
                this.codesByOrganizationId = {
                    ...this.codesByOrganizationId,
                    [organizationId]: [
                        code,
                        ...(this.codesByOrganizationId[organizationId] ?? []),
                    ],
                }
            })
        },
    )
}

export const useCodesByOrganizationId = (
    stores: Stores,
    organizationId: string,
) => {
    return stores.organizationCodes.codesByOrganizationId[organizationId]
}

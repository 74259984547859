import { makeObservable, observable, runInAction } from 'mobx'

type AsyncFunction<U> = (...args: any[]) => Promise<U>

export class ObservableTask<U, T extends AsyncFunction<U> = AsyncFunction<U>> {
    constructor(private readonly fn: T) {
        makeObservable(this, {
            isRunning: observable,
        })
    }

    public isRunning = false

    public async run(...args: Parameters<T>): Promise<U | undefined> {
        if (this.isRunning) return

        runInAction(() => {
            this.isRunning = true
        })

        try {
            return await this.fn(...args)
        } finally {
            runInAction(() => {
                this.isRunning = false
            })
        }
    }
}

import type { DateTime } from 'luxon'
import type { FC } from 'react'
import { useCallback } from 'react'
import { TableDateCell } from '../../../../../components/table/table-date-cell'
import { TableRow } from '../../../../../components/table/table-row'
import { TableTextCell } from '../../../../../components/table/table-text-cell'
import type { APIDocumentVersion } from '../../../../../types/document-version'
import { booleanColor, maybeNullColor } from '../../../../../util/style'

interface DocumentVersionRowProps {
    version: APIDocumentVersion
    now: DateTime
    onIsEnabledClick(versionId: string, isEnabled: boolean): void
}

export const DocumentVersionRow: FC<DocumentVersionRowProps> = ({
    version,
    now,
    onIsEnabledClick,
}) => {
    const _onIsEnabledClick = useCallback(() => {
        onIsEnabledClick(version.id, !version.is_enabled)
    }, [version.id, version.is_enabled, onIsEnabledClick])

    return (
        <TableRow>
            <TableTextCell style={{ color: maybeNullColor(version.parser_id) }}>
                {version.parser_id ?? 'None'}
            </TableTextCell>

            <TableTextCell
                style={{ color: maybeNullColor(version.parser_args) }}>
                {version.parser_args ?? 'None'}
            </TableTextCell>

            <TableTextCell
                style={{
                    color: booleanColor(version.is_enabled),
                    cursor: 'pointer',
                }}
                onClick={_onIsEnabledClick}>
                {version.is_enabled ? 'Yes' : 'No'}
            </TableTextCell>

            <TableTextCell>{version._count.sections}</TableTextCell>

            <TableDateCell date={version.created_at} now={now} />
        </TableRow>
    )
}

import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { formatDuration } from '../../util/format'
import type { Stores } from '../../util/store'
import { maybeNullColor, maybeZeroColor, progressColor } from '../../util/style'
import { useTestRunScore } from '../../util/test-run'
import { Checkbox } from '../checkbox/checkbox'
import { TableDateCell } from '../table/table-date-cell'
import { TableLinkCell } from '../table/table-link-cell'
import { TableRow } from '../table/table-row'
import { TableTextCell } from '../table/table-text-cell'
import type { TestRunsTableStore } from './test-runs-table-store'

export interface TestRunRowProps {
    tableStore: TestRunsTableStore
    stores: Stores
    testRunId: string
    now: DateTime
    onShowMenu(e: React.MouseEvent, testRunId: string): void
    showDocumentsColumn: boolean
}

export const TestRunRow: FC<TestRunRowProps> = observer(
    ({ tableStore, stores, testRunId, now, showDocumentsColumn }) => {
        const testRun = stores.testRuns.testRunsById[testRunId]

        const isRunningFromStore = stores.testRuns.processTestRun.isRunning(
            testRun.id,
        )

        const status = useMemo(() => {
            if (isRunningFromStore) {
                return {
                    text: 'Running...',
                    color: 'var(--cyan-color)',
                }
            }

            let startDate: DateTime | undefined
            let endDate: DateTime | undefined
            let isRunning = false
            let isWaitingToStart = true

            testRun.test_run_questions?.forEach((question) => {
                if (question.started_at) {
                    startDate = startDate
                        ? DateTime.min(startDate, question.started_at)
                        : question.started_at
                    isWaitingToStart = false
                }

                if (question.finished_at) {
                    endDate = endDate
                        ? DateTime.max(endDate, question.finished_at)
                        : question.finished_at
                }

                if (!question.finished_at) {
                    isRunning = true
                }
            })

            if (isWaitingToStart) {
                return {
                    text: 'Waiting to start...',
                    color: 'var(--comment-color)',
                }
            }

            if (isRunning) {
                return {
                    text: 'Running...',
                    color: 'var(--cyan-color)',
                }
            }

            const interval = startDate
                ? (endDate ?? now).diff(startDate)
                : undefined

            return {
                text: formatDuration(interval?.as('seconds') ?? 0),
                color: 'var(--)',
            }
        }, [testRun, now, isRunningFromStore])

        const score = useTestRunScore(testRun)

        const matches = useMemo(() => {
            const segments: string[] = []

            if (testRun.sections_before_match) {
                segments.push(`before: ${testRun.sections_before_match}`)
            }

            if (testRun.sections_after_match) {
                segments.push(`after: ${testRun.sections_after_match}`)
            }

            if (segments.length) {
                return (
                    <>
                        {testRun.embedding_matches}
                        <span
                            style={{
                                marginLeft: '0.5rem',
                                color: 'var(--comment-color)',
                            }}>
                            {segments.join(' ')}
                        </span>
                    </>
                )
            }

            return `${testRun.embedding_matches}`
        }, [testRun])

        const assertions = useMemo(() => {
            let result = 0

            testRun.test_run_questions?.forEach((question) => {
                result += question.acceptance_criteria.length
            })

            return result
        }, [testRun.test_run_questions])

        const documentTitle = useMemo(() => {
            if (!testRun.document) {
                return 'Unknown'
            }

            if (testRun.document.title.length <= 40) {
                return testRun.document.title
            }

            return testRun.document.title.slice(0, 40) + '...'
        }, [testRun.document])

        const _onSelectionChange = useCallback(
            (value: boolean) => {
                tableStore.setTestRunSelection(testRunId, value)
            },
            [tableStore, testRunId],
        )

        return (
            <TableRow key={testRun.id}>
                <td className="table-cell">
                    <Checkbox
                        value={tableStore.selectedTestRunIds[testRunId]}
                        onChange={_onSelectionChange}
                    />
                </td>

                <TableTextCell
                    style={{
                        color: progressColor(
                            score.percentage,
                            score.isComplete,
                        ),
                    }}>
                    {score.percentage}%
                </TableTextCell>

                <TableDateCell
                    date={testRun.created_at}
                    now={now}
                    style={{ color: 'var(--foreground-color)' }}
                    to={`/documents/${testRun.document_id}/test-runs/${testRun.id}`}
                />

                <TableTextCell
                    style={{ color: maybeNullColor(testRun.prompt ?? null) }}>
                    {testRun.prompt?.name ?? 'None'}
                </TableTextCell>

                <TableTextCell style={{ color: maybeNullColor(testRun.model) }}>
                    {testRun.model ?? 'None'}
                </TableTextCell>

                <TableTextCell
                    style={{
                        color: maybeNullColor(testRun.response_tokens_limit),
                    }}>
                    {testRun.response_tokens_limit ?? 'None'}
                </TableTextCell>

                <TableTextCell>{matches}</TableTextCell>

                {showDocumentsColumn && (
                    <TableLinkCell
                        to={`/documents/${testRun.document_id}/test-runs`}
                        title={testRun.document?.title}>
                        {documentTitle}
                    </TableLinkCell>
                )}

                <TableTextCell
                    style={{
                        color: maybeZeroColor(
                            testRun.test_run_questions?.length ?? 0,
                        ),
                    }}>
                    {testRun.test_run_questions?.length ?? 'Unknown'}
                </TableTextCell>

                <TableTextCell
                    style={{
                        color: maybeZeroColor(
                            testRun.test_run_questions?.length ?? 0,
                        ),
                    }}>
                    {assertions}
                </TableTextCell>

                <TableTextCell style={{ color: status.color }}>
                    {status.text}
                </TableTextCell>
            </TableRow>
        )
    },
)

import type { CSSProperties, FC } from 'react'
import type { APIRuleSetTextRule } from '../../types'

export interface TextRuleEditorProps {
    rule: APIRuleSetTextRule
    style?: CSSProperties
}

export const TextRuleEditor: FC<TextRuleEditorProps> = ({ rule, style }) => {
    return (
        <div
            style={{
                borderRadius: 6,
                border: '1px solid var(--selection-color)',
                padding: '0.5rem',
                ...style,
            }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}>
                <span className="small-text color-dimmed">Text</span>

                <div
                    style={{
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',
                        backgroundColor: 'var(--purple-color)',
                    }}
                />
            </div>

            <hr
                style={{
                    border: 'none',
                    borderTop: '1px solid var(--background-color)',
                    margin: '0.5rem 0',
                }}
            />

            <div>
                <span
                    className="small-text color-dimmed"
                    style={{ marginRight: '0.5rem' }}>
                    Hierarchy
                </span>
                <span className="small-text color-foreground">
                    {rule.hierarchy}
                </span>
            </div>

            <div>
                <span
                    className="small-text color-dimmed"
                    style={{ marginRight: '0.5rem' }}>
                    Font
                </span>
                {typeof rule.font === 'string' && (
                    <span
                        className="small-text color-dimmed"
                        style={{ marginRight: '0.25rem' }}>
                        !
                    </span>
                )}
                <span className="small-text color-foreground">{rule.font}</span>
            </div>

            <div>
                <span
                    className="small-text color-dimmed"
                    style={{ marginRight: '0.5rem' }}>
                    Size
                </span>
                {typeof rule.size_exact === 'number' && (
                    <span
                        className="small-text color-red"
                        style={{ marginRight: '0.25rem' }}>
                        !
                    </span>
                )}
                <span className="small-text color-foreground">
                    {rule.size_exact}
                </span>
            </div>
        </div>
    )
}

import type { APITestQuestion } from '@visorpro/client'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback } from 'react'
import type { Stores } from '../../util/store'
import { useModal } from '../modal'
import { QuestionModal } from '../question-modal/question-modal'
import { Table } from '../table/table'
import { TableHeader } from '../table/table-header'
import { TestQuestionRow } from './test-question-row'
import {
    TestQuestionActionKind,
    TestQuestionActionsModal,
} from './test-questions-actions-modal'

export interface TestQuestionsTableProps {
    stores: Stores
    testQuestions: APITestQuestion[]
}

interface TestQuestionPopupContent {
    testQuestionId: string
    kind?: TestQuestionActionKind
}

export const TestQuestionsTable: FC<TestQuestionsTableProps> = observer(
    ({ stores, testQuestions }) => {
        const now = DateTime.now()
        const testQuestionsPopup = useModal<TestQuestionPopupContent>()

        const onShowMenu = useCallback(
            (e: React.MouseEvent, testQuestionId: string) => {
                testQuestionsPopup.open(e, { testQuestionId })
            },
            [testQuestionsPopup],
        )

        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Question</TableHeader>
                            <TableHeader>Assertions</TableHeader>
                            <TableHeader>Created</TableHeader>
                        </tr>
                    </thead>

                    <tbody>
                        {testQuestions.map((testQuestion) => {
                            return (
                                <TestQuestionRow
                                    key={testQuestion.id}
                                    testQuestionId={testQuestion.id}
                                    now={now}
                                    stores={stores}
                                    onShowMenu={onShowMenu}
                                />
                            )
                        })}
                    </tbody>
                </Table>

                {testQuestionsPopup.state &&
                    testQuestionsPopup.state?.data?.kind === undefined && (
                        <TestQuestionActionsModal
                            anchor={testQuestionsPopup.state.anchor}
                            testQuestionId={
                                testQuestionsPopup.state.data!.testQuestionId
                            }
                            onClose={testQuestionsPopup.close}
                            onAction={(testQuestionId, kind) => {
                                if (kind === TestQuestionActionKind.Delete) {
                                    stores.testQuestions.deleteTestQuestion.run(
                                        testQuestionId,
                                    )
                                    testQuestionsPopup.close()
                                } else {
                                    testQuestionsPopup.update({
                                        testQuestionId: testQuestionId,
                                        kind,
                                    })
                                }
                            }}
                        />
                    )}

                {testQuestionsPopup.state?.data?.kind ===
                    TestQuestionActionKind.Update && (
                    <QuestionModal
                        testQuestionId={
                            testQuestionsPopup.state.data.testQuestionId
                        }
                        stores={stores}
                        anchor={testQuestionsPopup.state.anchor}
                        onClose={testQuestionsPopup.close}
                    />
                )}
            </>
        )
    },
)

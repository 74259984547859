import { makeObservable, observable, runInAction } from 'mobx'
import { useEffect } from 'react'
import type { APIUser } from '@visorpro/client'

import { visorPRORestClient } from '../util/api'
import { ObservableTask } from '../util/observable-task'

export class OrganizationMemberStore {
    public organizationId: string | null = null
    public membersById: Record<string, APIUser> = {}

    constructor() {
        makeObservable(this, {
            organizationId: observable,
            membersById: observable,
        })
    }

    public get isFetching() {
        return this.fetchOrganizationMembers.isRunning
    }

    public get isUpdating() {
        return (
            this.addOrganizationMembership.isRunning ||
            this.removeOrganizationMembership.isRunning
        )
    }

    public fetchOrganizationMembers = new ObservableTask(
        async (organizationId: string) => {
            runInAction(() => {
                this.organizationId = organizationId
            })

            const response =
                await visorPRORestClient.organization.getMembers(organizationId)

            runInAction(() => {
                this.membersById = {}
                response.items.forEach((user: APIUser) => {
                    this.membersById[user.id] = user
                })
            })
        },
    )

    public addOrganizationMembership = new ObservableTask(
        async (userId: string, organizationId: string) => {
            const membership =
                await visorPRORestClient.organization.createMembership(
                    organizationId,
                    userId,
                )

            runInAction(() => {
                this.membersById[userId] = membership.user!
            })
        },
    )

    public removeOrganizationMembership = new ObservableTask(
        async (userId: string, organizationId: string) => {
            await this.removeOrganizationMemberships.run([userId], organizationId)
        },
    )

    public removeOrganizationMemberships = new ObservableTask(
        async (userIds: string[], organizationId: string) => {
            await Promise.all(
                userIds.map((userId) =>
                    visorPRORestClient.organization.deleteMembership(
                        organizationId,
                        userId,
                    ),
                ),
            )

            runInAction(() => {
                userIds.forEach((userId) => {
                    delete this.membersById[userId]
                })
            })
        },
    )
}

export const useOrganizationMembers = (
    store: OrganizationMemberStore,
    organizationId: string,
) => {
    useEffect(() => {
        if (organizationId !== store.organizationId) {
            void store.fetchOrganizationMembers.run(organizationId)
        }
    }, [organizationId, store, store.organizationId])

    return Object.values(store.membersById)
}

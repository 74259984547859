import { observer } from 'mobx-react-lite'
import { useCallback, type FC } from 'react'
import { UsersTable } from '../../components/users-table'
import type { Stores } from '../../util/store'
import { useUsers } from '../../stores/user-store'
import { useOrganizations } from '../../stores/organization-store'
import { useEffectOnce } from 'react-use'

export interface UsersListPageProps {
    stores: Stores
}

export const UsersListPage: FC<UsersListPageProps> = observer(({ stores }) => {
    const users = useUsers(stores.users)
    const organizations = useOrganizations(stores.organizations)

    useEffectOnce(() => {
        void stores.users.fetchUsers.run()
    })

    const setOrganizations = useCallback(async (userId: string, additions: string[], deletions: string[]) => {
        await Promise.all([
            stores.users.addOrganizationMemberships.run(userId, additions),
            stores.users.removeOrganizationMemberships.run(userId, deletions),
        ])
    }, [stores.users])

    return (
        <UsersTable
            users={users}
            organizations={organizations}
            setOrganizations={setOrganizations}
            isLoading={stores.users.isFetching && !stores.users.isFetched}
            showProgressBars={stores.users.isUpdating}
        />
    )
})

import type { APIOrganization, APIOrganizationRaw } from '@visorpro/client'
import { RawTypeConverter } from '@visorpro/client'
import { DateTime } from 'luxon'

export interface APIDataSetBase {
    id: string
    name: string
    documents_count: number
}

export interface APIDataSetRaw extends APIDataSetBase {
    organizations: APIOrganizationRaw[]
    created_at: string
    updated_at: string
}

export interface APIDataSet extends APIDataSetBase {
    organizations: APIOrganization[]
    created_at: DateTime
    updated_at: DateTime
}

export const rawDataSetToDataSet = (rawDataSet: APIDataSetRaw): APIDataSet => ({
    ...rawDataSet,
    organizations: rawDataSet.organizations.map(RawTypeConverter.fixTimestamps),
    created_at: DateTime.fromISO(rawDataSet.created_at),
    updated_at: DateTime.fromISO(rawDataSet.updated_at),
})

import { makeObservable, observable, runInAction } from 'mobx'
import type { Stores } from '../../util/store'

const LS_TEST_RUN_CONFIG_KEY = 'testRunConfig'

export interface TestRunConfigFormStoreOptions {
    documentId?: string
    testRunIds?: string[]
}

export class TestRunConfigFormStore {
    public promptId: string | undefined
    public model: string | undefined = 'gpt-4'
    public responseTokensLimit: number | undefined = 1000
    public embeddingMatches: number | undefined = 4
    public sectionsBeforeMatch: number | undefined = 0
    public sectionsAfterMatch: number | undefined = 0

    constructor(private readonly options: TestRunConfigFormStoreOptions) {
        this.initFromLocalStorage()

        makeObservable(this, {
            promptId: observable,
            model: observable,
            responseTokensLimit: observable,
            embeddingMatches: observable,
            sectionsBeforeMatch: observable,
            sectionsAfterMatch: observable,
        })
    }

    private initFromLocalStorage = () => {
        const config = localStorage.getItem(LS_TEST_RUN_CONFIG_KEY)

        if (config) {
            const parsed = JSON.parse(config)

            this.promptId = parsed.prompt_id
            this.model = parsed.model
            this.responseTokensLimit = parsed.response_tokens_limit
            this.embeddingMatches = parsed.embedding_matches
            this.sectionsBeforeMatch = parsed.sections_before_match
            this.sectionsAfterMatch = parsed.sections_after_match
        }
    }

    public setPromptId = (promptId: string) => {
        runInAction(() => {
            this.promptId = promptId
        })
    }

    public setModel = (model: string) => {
        runInAction(() => {
            this.model = model
        })
    }

    public setResponseTokensLimit = (responseTokensLimit: string) => {
        runInAction(() => {
            let value = this.getInteger(responseTokensLimit)

            if (typeof value === 'number') {
                value = Math.max(1, value)
                this.responseTokensLimit = value
            }
        })
    }

    public setEmbeddingMatches = (embeddingMatches: string) => {
        runInAction(() => {
            let value = this.getInteger(embeddingMatches)

            if (typeof value === 'number') {
                value = Math.max(1, value)
                this.embeddingMatches = value
            }
        })
    }

    public setSectionsBeforeMatch = (sectionsBeforeMatch: string) => {
        runInAction(() => {
            let value = this.getInteger(sectionsBeforeMatch)

            if (typeof value === 'number') {
                value = Math.max(0, value)
                this.sectionsBeforeMatch = value
            }
        })
    }

    public setSectionsAfterMatch = (sectionsAfterMatch: string) => {
        runInAction(() => {
            let value = this.getInteger(sectionsAfterMatch)

            if (typeof value === 'number') {
                value = Math.max(0, value)
                this.sectionsAfterMatch = value
            }
        })
    }

    private getInteger = (value: string) => {
        if (value === '') {
            return 0
        }

        const parsed = parseInt(value)

        if (isNaN(parsed)) {
            return undefined
        }

        return parsed
    }

    public submit = async (stores: Stores) => {
        const config = {
            prompt_id: this.promptId?.trim() || undefined,
            model: this.model?.trim() || undefined,
            response_tokens_limit: this.responseTokensLimit,
            embedding_matches: this.embeddingMatches,
        }

        const { documentId, testRunIds } = this.options

        if (documentId) {
            await stores.testRuns.createTestRun.run(documentId, config)
        } else if (testRunIds) {
            await stores.testRuns.copyTestRuns.run(testRunIds, config)
        } else {
            throw new Error('No documentId or testRunIds provided')
        }

        // by saving this to local storage we make it easier to
        // reuse the last config
        localStorage.setItem(LS_TEST_RUN_CONFIG_KEY, JSON.stringify(config))
    }
}
